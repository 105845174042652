import React, { useMemo } from "react";

import { Domain } from "./Pages/Settings/Domain/Domain";
import Icon from "@mdi/react";
import { mdiCalendarCheck, mdiCog, mdiDelete, mdiDeleteAlert, mdiDeleteForever, mdiDomain } from "@mdi/js";
import { formatForId } from "./Utils/Lang/IntlHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faSitemap } from "@fortawesome/pro-solid-svg-icons";
import { Settings } from "./Pages/Settings/Settings";
import EventList from "./Pages/Events/EventList";
import { useLocation } from "react-router-dom";
import DomainView from "./Pages/DomainView";
import GlobalViews from "./Pages/GlobalViews";
import StandaloneMeteogram from "./Pages/Meteogram/StandaloneMeteogram";
import _ from "loadsh";
import { useAppAccess } from "./Utils/Data/hooks/server";
import { useIntl } from "react-intl";
import { useRequirePermissionCheck } from "./Utils/Permissions/RequirePermission";
import { useHasAnyPermission } from "./Utils/Permissions/RequireAnyPermission";
import { DomainSettingsLanding } from "./Pages/Settings/Domain/DomainSettingsLanding";
import { useViewsList } from "./Api";
import { DeviceDataExport } from "./Pages/DeviceDataExport";
import { faFileCsv, faUsers, faUsersClass } from "@fortawesome/pro-light-svg-icons";
import ActiveUsers from "./Pages/ActiveUsers";
import DeletionLog from "./Pages/DeletionLog";

export const AppRouting = [
    {
        path: "/domain/:id",
        handler: <DomainView />,
    },
    {
        path: "/settings/domain/:id",
        handler: <Domain />,
    },
    {
        path: "/settings/",
        handler: <DomainSettingsLanding />,
    },
    {
        path: "/events/",
        handler: <EventList />,
    },
    {
        path: "/views/:id?",
        handler: <GlobalViews />,
    },
    {
        path: "/meteogram/",
        handler: <StandaloneMeteogram />,
    },
    {
        path: "/system/",
        handler: <Settings />,
    },
    {
        path: "/deviceExport/",
        handler: <DeviceDataExport />,
    },
    {
        path: "/activeUsers/",
        handler: <ActiveUsers />,
    },
    {
        path: "/deletionLog/",
        handler: <DeletionLog />,
    },
];

function hasDomainSettingsPermission(acl) {
    return !!_.find(acl.permissions, (perm) => perm === "others__domain_settings");
}

export function useSiteMenu() {
    const appAccess = useAppAccess();
    const intl = useIntl();
    const hasDomainAdminPerms = useHasAnyPermission({ permission: "others__domain_settings" });
    const hasDeviceHistoryPerms = useHasAnyPermission({ permission: "dev__data_history" });

    const hasSystemAdminPerms = useRequirePermissionCheck({ permission: "others__domain_settings" });
    const hasActiveUsersPerms = useHasAnyPermission({ permission: "others__active_users" });

    const domains = useMemo(() => _.orderBy(appAccess.domains, [(d) => d.name.toLowerCase()], ["asc"]), [appAccess]);
    const adminDomains = useMemo(() => (hasSystemAdminPerms ? domains : _.filter(domains, hasDomainSettingsPermission)), [domains]);

    return useMemo(() => {
        let domainViews = [];
        for (const domain of domains) {
            domainViews.push({
                path: "/domain/" + domain.id + "/",
                name: domain.name,
                items: _.orderBy(
                    _.map(
                        _.filter(domain?.device_groups, (group) => group.id > 0),
                        (item) => ({
                            path: "/domain/" + domain.id + "/group/" + item.id + "/table/",
                            name: item.name,
                            icon: <FontAwesomeIcon icon={faEye} size={"lg"} />,
                        })
                    ),
                    ["name"],
                    ["asc"]
                ),
                icon: <Icon path={mdiDomain} size={1} />,
            });
        }

        let result = [];
        result = [...result, ...domainViews];

        if (hasDeviceHistoryPerms) {
            result.push({
                path: "/deviceExport/",
                name: formatForId(intl, "pages.main.menu.deviceExport"),
                icon: <FontAwesomeIcon icon={faFileCsv} size={"lg"} />,
            });
        }

        if (hasDomainAdminPerms) {
            result = [
                ...result,

                {
                    path: "/settings/",
                    name: formatForId(intl, "pages.main.menu.settings"),
                    icon: <Icon path={mdiCog} size={1} />,
                    items: adminDomains.map((domain) => ({
                        name: domain.name,
                        icon: <Icon path={mdiDomain} size={1} />,
                        path: `/settings/domain/${domain.id}/`,
                        menu: [
                            {
                                path: "deviceGroups",
                                name: formatForId(intl, "pages.main.menu.deviceGroups"),
                            },
                            {
                                path: "dataSources",
                                name: formatForId(intl, "pages.main.menu.dataSources"),
                            },
                            {
                                path: "configurationTemplates",
                                name: formatForId(intl, "pages.main.menu.configurationTemplates"),
                            },
                        ],
                    })),
                },
            ];
        }

        if (hasActiveUsersPerms) {
            result = [
                ...result,
                {
                    path: "/activeUsers/",
                    name: formatForId(intl, "pages.main.menu.activeUsers"),
                    icon: <FontAwesomeIcon icon={faUsersClass} size={"lg"} />,
                },
            ];
        }

        if (hasSystemAdminPerms) {
            result = [
                ...result,
                {
                    path: "/system/",
                    name: formatForId(intl, "pages.main.menu.system"),
                    icon: <FontAwesomeIcon icon={faSitemap} size={"lg"} />,
                    menu: [
                        {
                            path: "users",
                            name: formatForId(intl, "pages.main.menu.settings.users"),
                        },
                        {
                            path: "userGroups",
                            name: formatForId(intl, "pages.main.menu.settings.userGroups"),
                        },
                        {
                            path: "domains",
                            name: formatForId(intl, "pages.main.menu.settings.domains"),
                        },
                        {
                            path: "roles",
                            name: formatForId(intl, "pages.main.menu.settings.roles"),
                        },
                        {
                            path: "views",
                            name: formatForId(intl, "pages.main.menu.settings.views"),
                        },
                        {
                            path: "data-export",
                            name: formatForId(intl, "pages.main.menu.settings.dataExport"),
                        },
                        {
                            path: "nira-config",
                            name: formatForId(intl, "pages.main.menu.settings.nira"),
                        },
                    ],
                },
                {
                    path: "/events/",
                    name: formatForId(intl, "pages.main.menu.eventList"),
                    icon: <Icon path={mdiCalendarCheck} size={1} />,
                },
                {
                    path: "/deletionLog/",
                    name: formatForId(intl, "pages.main.menu.deletionLog"),
                    icon: <Icon path={mdiDelete} size={1} />,
                },
            ];
        }

        return result;
    }, [appAccess, hasDomainAdminPerms, domains]);
}

export function useViewsMenu() {
    const appAccess = useAppAccess();

    const { views: viewsWithCount } = useViewsList(true);

    return useMemo(() => {
        const processViews = (views, hasDeviceCount) =>
            views
                .filter((view) => view.is_active)
                .map((view) => ({
                    path: "/views/" + view.id + (_.isEmpty(view.initial_view) ? "/table/" : `\/${view.initial_view}\/`),
                    name: view.name,
                    icon: <FontAwesomeIcon icon={faEye} size={"lg"} />,
                    deviceCount: hasDeviceCount ? view.device_count : "...",
                }));

        if (viewsWithCount.length > 0) {
            return processViews(viewsWithCount, true);
        } else {
            return [];
        }
    }, [appAccess, viewsWithCount]);
}

export function useGetCurrentPagePath(menu) {
    const sortedMenu = useMemo(() => {
        const result = [];

        if (menu) {
            for (const item of menu) {
                if (item.items) {
                    for (const nested of item.items) {
                        result.push(nested);
                    }
                }

                result.push(item);
            }
        }

        result.sort((a, b) => b.path.length - a.path.length);

        return result;
    }, [menu]);

    const location = useLocation();

    return useMemo(() => {
        return sortedMenu.find((item) => location.pathname.startsWith(item.path))?.path;
    }, [sortedMenu, location]);
}
