import React, { memo, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useIntl } from "react-intl";
import clsx from "clsx";
import { formatForId } from "../../Utils/Lang/IntlHelper";
import { openDialog } from "../../Utils/Data/actions/gui";
import { useStore } from "react-redux";
import { ALERT_FAILURE, ALERT_STATE, useFormattedWarnings } from "../../Utils/Data/AlertFormatter";
import { Tooltip, Typography, useTheme } from "@material-ui/core";
import { usePictograms } from "../../Utils/Data/PictogramAlgorithms";
import AlertView, { ALERT_VIEW_TYPE } from "./AlertView";

export const VIEW_TYPE = { one_row: 1, grid: 2, large: 3, one_row_warning: 4, one_row_warning_and_states: 5 };

function getBgColor(theme, level, state) {
    switch (state) {
        case NONE_STATE:
            return "#18202C";
        case ACTIVE_STATE:
            return "#343b46";
        case ACTIVE_STATE_UNCORFIMED:
            return theme.palette.warnings[`level_${level}`].primary;
    }
}

const itemMargin = {
    marginLeft: 2,
    marginRight: 2,
};
const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        flex: 1,
    },
    verticalGroup: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        alignItems: "center",
    },
    horizontalGroup: {
        display: "flex",
        flexDirection: "row",
        flex: 1,
        alignItems: "center",
    },
    stateItemBase: {
        ...theme.custom.baseClickableDiv,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    stateItemLarge: {
        width: "50%",
    },
    stateItemSmall: {
        width: "100%",
    },
    pictogramItem: {
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 36,
        ...itemMargin,
    },
    alertNumberItem: {
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        ...itemMargin,
    },
    stateRow: {
        flex: 1,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    stateRowVertical: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
}));

const NONE_STATE = 0;
const ACTIVE_STATE = 1;
const ACTIVE_STATE_UNCORFIMED = 2;

function PictogramItem({ item, classes, fontSize = "3rem" }) {
    return (
        <Tooltip title={item.tooltip}>
            <div className={classes.pictogramItem} style={{ fontSize }}>
                {item.icon}
            </div>
        </Tooltip>
    );
}

export function StateRow({ deviceState, device, vertical = false, itemHeight = 23 }) {
    const classes = useStyles();

    const warnings = useMemo(() => (deviceState?.active_warnings ? deviceState?.active_warnings : []), [deviceState]);

    return (
        <div className={vertical ? classes.stateRowVertical : classes.stateRow}>
            <StateItem
                deviceState={deviceState}
                device={device}
                level={ALERT_STATE}
                warnings={warnings}
                small={true}
                margin={false}
                maxWidth={30}
                marginBottom={false}
                classes={classes}
                itemHeight={itemHeight}
            />
            <div style={{ paddingLeft: vertical ? 0 : 4, paddingTop: vertical ? 4 : 0 }}></div>
            <StateItem
                deviceState={deviceState}
                device={device}
                level={ALERT_FAILURE}
                warnings={warnings}
                small={true}
                margin={false}
                maxWidth={30}
                marginBottom={false}
                classes={classes}
                itemHeight={itemHeight}
            />
        </div>
    );
}

function StateItem({ device, deviceState, level, warnings, small = false, margin = !small, marginBottom = true, maxWidth, classes, itemHeight = small ? 23 : 27 }) {
    const intl = useIntl();
    const store = useStore();
    const theme = useTheme();

    const styleProps = useMemo(() => {
        const getLevelState = (level) => {
            const unconfirmed = warnings.find((warning) => warning.level === level && warning.confirmation === null);
            if (unconfirmed) {
                return ACTIVE_STATE_UNCORFIMED;
            }
            const active = warnings.find((warning) => warning.level === level);
            if (active) {
                return ACTIVE_STATE;
            }
            return NONE_STATE;
        };

        return { level: getLevelState(level), small, margin, maxWidth, marginBottom };
    }, [deviceState, small, margin, marginBottom]);

    const openAlertDialog = (level) =>
        openDialog(store, {
            type: "alertHistory",
            entity: { singleDevice: device, defaultSeverity: [level] },
        });

    const formattedWarnings = useFormattedWarnings(warnings, deviceState, [level]);

    const label = useMemo(() => {
        let key;
        if (small) {
            key = level === ALERT_STATE ? "pages.device.stateView.stateLabel.shortcut" : "pages.device.stateView.errorLabel.shortcut";
        } else {
            key = level === ALERT_STATE ? "pages.device.stateView.stateLabel.name" : "pages.device.stateView.errorLabel.name";
        }
        return formatForId(intl, key);
    }, [level, small]);

    const stateItemStyles = useMemo(
        () => ({
            cursor: styleProps.level !== NONE_STATE && "pointer",
            minWidth: styleProps.level === NONE_STATE ? 0 : 20,
            maxWidth: styleProps.maxWidth,
            marginBottom: small && styleProps.marginBottom ? theme.spacing(0.5) : 0,
            height: itemHeight,
        }),
        [styleProps]
    );

    const failureStyles = useMemo(
        () => ({
            ...stateItemStyles,
            backgroundColor: getBgColor(theme, 30, styleProps.level),
            marginLeft: styleProps.margin ? theme.spacing(0.25) : 0,
            color: styleProps.level === ACTIVE_STATE_UNCORFIMED ? theme.palette.warnings.level_30.textColor : "",
        }),
        [styleProps, stateItemStyles]
    );

    const stateStyles = useMemo(
        () => ({
            ...stateItemStyles,
            backgroundColor: getBgColor(theme, 20, styleProps.level),
            marginRight: styleProps.margin ? theme.spacing(0.25) : 0,
            color: styleProps.level === ACTIVE_STATE_UNCORFIMED ? theme.palette.warnings.level_20.textColor : "",
        }),
        [styleProps, stateItemStyles]
    );

    return styleProps.level === NONE_STATE ? (
        <div className={clsx(classes.stateItemBase, small ? classes.stateItemSmall : classes.stateItemLarge)} style={stateItemStyles} />
    ) : (
        <Tooltip title={formattedWarnings}>
            <div
                className={clsx(classes.stateItemBase, small ? classes.stateItemSmall : classes.stateItemLarge)}
                onClick={() => openAlertDialog(level)}
                style={level === ALERT_STATE ? stateStyles : failureStyles}
            >
                <Typography variant={"body1"}>{label}</Typography>
            </div>
        </Tooltip>
    );
}

function PictogramState({ device, deviceState, type = VIEW_TYPE.large }) {
    const classes = useStyles();
    const warnings = useMemo(() => (deviceState?.active_warnings ? deviceState?.active_warnings : []), [deviceState]);
    const pictograms = usePictograms(device, deviceState);

    switch (type) {
        case VIEW_TYPE.large:
            return (
                <div className={classes.root}>
                    <div className={classes.horizontalGroup}>
                        <StateItem deviceState={deviceState} device={device} level={ALERT_STATE} warnings={warnings} classes={classes} />
                        <StateItem deviceState={deviceState} device={device} level={ALERT_FAILURE} warnings={warnings} classes={classes} />
                    </div>
                    <div className={classes.horizontalGroup}>
                        <PictogramItem item={pictograms.precip} classes={classes} />
                        <PictogramItem item={pictograms.wind} classes={classes} />
                        <PictogramItem item={pictograms.road} classes={classes} />
                    </div>
                </div>
            );

        case VIEW_TYPE.grid:
            return (
                <div className={classes.root}>
                    <div className={classes.horizontalGroup}>
                        <div className={classes.verticalGroup}>
                            <StateItem deviceState={deviceState} device={device} level={ALERT_STATE} warnings={warnings} small={true} classes={classes} />
                            <StateItem deviceState={deviceState} device={device} level={ALERT_FAILURE} warnings={warnings} small={true} classes={classes} />
                        </div>
                        <PictogramItem item={pictograms.precip} classes={classes} />
                    </div>
                    <div className={classes.horizontalGroup} style={{ paddingTop: 8 }}>
                        <PictogramItem item={pictograms.wind} classes={classes} />
                        <PictogramItem item={pictograms.road} classes={classes} />
                    </div>
                </div>
            );
        case VIEW_TYPE.one_row:
            return (
                <div className={classes.root}>
                    <div className={classes.horizontalGroup}>
                        <div className={classes.verticalGroup}>
                            <StateItem deviceState={deviceState} device={device} level={ALERT_STATE} warnings={warnings} small={true} classes={classes} />
                            <StateItem deviceState={deviceState} device={device} level={ALERT_FAILURE} warnings={warnings} small={true} classes={classes} />
                        </div>
                        <PictogramItem item={pictograms.precip} classes={classes} />
                        <PictogramItem item={pictograms.wind} classes={classes} />
                        <PictogramItem item={pictograms.road} classes={classes} />
                    </div>
                </div>
            );
        case VIEW_TYPE.one_row_warning:
            return (
                <div className={classes.root}>
                    <div className={classes.horizontalGroup}>
                        <div className={classes.alertNumberItem}>
                            <AlertView deviceState={deviceState} device={device} mode={ALERT_VIEW_TYPE.number} />
                        </div>
                        <PictogramItem item={pictograms.precip} classes={classes} />
                        <PictogramItem item={pictograms.wind} classes={classes} />
                        <PictogramItem item={pictograms.road} classes={classes} />
                    </div>
                </div>
            );
        case VIEW_TYPE.one_row_warning_and_states:
            return (
                <div className={classes.root}>
                    <div className={classes.horizontalGroup}>
                        <div className={classes.alertNumberItem}>
                            <AlertView deviceState={deviceState} device={device} mode={ALERT_VIEW_TYPE.number} />
                        </div>
                        <StateRow deviceState={deviceState} device={device} vertical={true} itemHeight={15} />
                        <PictogramItem item={pictograms.precip} classes={classes} fontSize={"2.5rem"} />
                        <PictogramItem item={pictograms.wind} classes={classes} fontSize={"2.5rem"} />
                        <PictogramItem item={pictograms.road} classes={classes} fontSize={"2.5rem"} />
                    </div>
                </div>
            );
        default:
            return <div />;
    }
}

export default memo(PictogramState);
