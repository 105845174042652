import React, {useMemo} from 'react';

import {DateRangeField} from "./DateRangeField";
import {YearType} from "./DateRangeField/hooks";

const ExcludeYearType = [YearType];
export default function DateRangePicker({value, onChange, disabled, dense}) {

    return (
        <DateRangeField value={value} onChange={onChange} disabled={disabled} dense={dense}
                        excludeTypes={ExcludeYearType}/>
    );
}