import { FormControl, FormLabel, Grid, MenuItem, Select, useTheme } from "@material-ui/core";
import _ from "loadsh";
import { formatForId } from "../../../Utils/Lang/IntlHelper";
import React, { useMemo, useState } from "react";
import {
    DateIntervalType,
    Last24HoursType,
    Last36HoursType,
    Last7DaysType,
    Last8HoursType,
    useDateRangePickerLogic,
    useDefaultDateRangePickerValue,
    useMaxDate,
    YearMonthType,
    YearType,
} from "./hooks";
import { DatePickerWrapper, YearMonthDateView, YearMonthView, YearView } from "./DatePickerWrapper";
import { useIntl } from "react-intl";
import NotchedOutline from "@material-ui/core/OutlinedInput/NotchedOutline";
import clsx from "clsx";
import { formatTime } from "../../../Utils/Data/Time";

const Types = [Last8HoursType, Last24HoursType, Last36HoursType, Last7DaysType, YearType, YearMonthType, DateIntervalType];

function excludeTypesFilter(excludeTypes, type) {
    return excludeTypes.indexOf(type) === -1;
}

export function DateRangeField({ disabled, value, onChange, dense, outline = true, excludeTypes = null }) {
    const maxDate = useMaxDate();
    const defaultValue = useDefaultDateRangePickerValue(Last8HoursType);

    const callingValue = value;
    value = value || defaultValue;
    if (value !== callingValue) {
        onChange(value);
    }

    const { onModeSelectionChanged, onEndChange, onBeginChange } = useDateRangePickerLogic({
        value,
        onChange,
        maxDate,
    });

    const intl = useIntl();
    const [focused, setFocused] = useState(false);

    const rootClassName = outline ? clsx("MuiOutlinedInput-root", focused && "Mui-focused") : null;
    const className = outline ? clsx("MuiOutlinedInput-input", "DateRangeField-outline", dense && "MuiInputBase-inputMarginDense", dense && "MuiOutlinedInput-inputMarginDense") : null;

    const types = useMemo(() => (excludeTypes ? _.filter(Types, (type) => excludeTypesFilter(excludeTypes, type)) : Types), [excludeTypes]);
    return (
        <div className={rootClassName} style={{ display: "flex", alignItems: "center", height: 41.5 }}>
            <Grid container alignItems={"center"} className={className} style={dense && { paddingTop: 3, paddingBottom: 3 }} onFocus={() => setFocused(true)} onBlur={() => setFocused(false)}>
                <Select
                    disabled={disabled}
                    variant={"standard"}
                    MenuProps={{
                        getContentAnchorEl: () => null,
                        anchorOrigin: { vertical: "bottom", horizontal: "left" },
                        transformOrigin: { vertical: "top", horizontal: "left" },
                    }}
                    style={{ width: "12em", marginRight: 6 }}
                    value={value.type}
                    multiple={false}
                    onChange={onModeSelectionChanged}
                >
                    {types.map((value) => (
                        <MenuItem key={value} value={value}>
                            {formatForId(intl, `dateRange.${value}`)}{" "}
                        </MenuItem>
                    ))}
                </Select>
                {value.type === YearType && <DatePickerWrapper value={value.begin} maxDate={maxDate} views={YearView} onChange={onBeginChange} />}
                {value.type === YearMonthType && <DatePickerWrapper value={value.begin} maxDate={maxDate} views={YearMonthView} onChange={onBeginChange} />}
                {value.type === DateIntervalType && (
                    <>
                        <DatePickerWrapper value={value.begin} maxDate={maxDate} views={YearMonthDateView} onChange={onBeginChange} />
                        <DatePickerWrapper value={value.end} maxDate={maxDate} views={YearMonthDateView} onChange={onEndChange} />{" "}
                    </>
                )}
                {value.type === Last8HoursType || value.type === Last24HoursType || value.type === Last7DaysType || value.type === Last36HoursType ? (
                    <div>
                        {formatForId(intl, "dateRange.beginAt")} {formatTime(value.begin)}
                    </div>
                ) : null}
            </Grid>
            {outline && <NotchedOutline labelWidth={1} notched={false} className={"MuiOutlinedInput-notchedOutline"} /*style={{top: -5, bottom: -2.5}}*/ />}
        </div>
    );
}

export function DateRangeFormField({ disabled, value, label, required, fieldId, changeHandle }) {
    return (
        <FormControl fullWidth required={required}>
            <FormLabel component="legend">{label}</FormLabel>
            <DateRangeField
                outline={false}
                value={value}
                disabled={disabled}
                onChange={(value) => {
                    changeHandle({
                        target: {
                            name: fieldId,
                            value: value,
                        },
                    });
                }}
            />
        </FormControl>
    );
}
