import React, { useMemo, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { Chip, FormControl, FormControlLabel, FormHelperText, FormLabel, Input, InputLabel, Menu, MenuItem, Popover, Radio, RadioGroup, Select, Typography, useTheme } from "@material-ui/core";
import { formatForId } from "../../Utils/Lang/IntlHelper";
import QueryAssist from "react-query-assist";
import { useIntl } from "react-intl";
import Button from "@material-ui/core/Button";
import { useAppAccess } from "../../Utils/Data/hooks/server";
import _ from "loadsh";
import { SingleEntitySelectionDialog } from "../SingleEntitySelectionDialog";
import { faPlus, faArrowRight, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EntitySelectionDialog } from "../EntitySelectionDialog";
import { useDomainGroupDevices } from "../../Api";
import { makeStyles } from "@material-ui/styles";
import { faRotateLeft, faSortAlphaDownAlt, faSortAlphaUpAlt, faSortNumericDown, faSortNumericUp } from "@fortawesome/pro-light-svg-icons";
import { DraggableChip } from "./DropdownField";
import clsx from "clsx";
import NotchedOutline from "@material-ui/core/OutlinedInput/NotchedOutline";

const Dialogs = {
    Domains: 0,
    DeviceGroups: 1,
    Devices: 2,
};

const Selection = ["domain", "deviceGroup", "device"];

function Delim() {
    return (
        <div style={{ color: "lightgray" }}>
            <FontAwesomeIcon icon={faArrowRight} />
        </div>
    );
}

function DeviceButton({ devices, deviceEntities, onClick, disabled }) {
    const intl = useIntl();
    const size = _.size(devices);
    const name = useMemo(() => {
        if (size === 1) {
            const device = _.find(deviceEntities, (device) => device.id === devices[0]);
            return device?.name || "";
        }
    }, [devices, deviceEntities, size]);

    return (
        <Button disabled={disabled} onClick={onClick}>
            {size === 1 && !_.isEmpty(name) ? name : formatForId(intl, `GlobalDeviceQueryFilter.${size === 0 ? "empty" : "selected"}.${Selection[Dialogs.Devices]}`, { size: size })}
        </Button>
    );
}

const useDeviceFilterStyles = makeStyles((theme) => ({
    filterButton: {
        "&>.MuiButton-label": {
            display: "inline-block",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        },
    },
    allFilterButton: {
        "&>.MuiButton-label": {
            display: "inline-block",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        },
    },
}));

function DeviceFilterRow({ value, disabled, onChange, allowUnselectLast }) {
    const appAccess = useAppAccess();
    const intl = useIntl();
    const [dialog, setDialog] = useState(-1);
    const classes = useDeviceFilterStyles();

    const domains = useMemo(
        () =>
            _.map(appAccess.domains, (domain) => ({
                id: domain.id,
                name: domain.name,
            })),
        [appAccess]
    );

    const deviceGroups = useMemo(
        () => (_.size(value) > Dialogs.Domains ? _.filter(_.find(appAccess.domains, (domain) => domain.id === value[Dialogs.Domains].id)?.device_groups, (group) => group.id > 0) || [] : []),
        [domains, value]
    );

    const { devices } = useDomainGroupDevices({
        domainId: value[Dialogs.Domains]?.id,
        groupId: value[Dialogs.DeviceGroups]?.id,
    });

    const closeDialog = () => {
        setDialog(-1);
    };
    const onSelect = (selection) => {
        const newVal = _.clone(value || []);

        const newSelection = _.clone(selection);
        delete newSelection.permissions;

        newVal[dialog] = newSelection;

        if (_.isEmpty(newSelection)) {
            newVal.splice(dialog);
        } else if (dialog < _.size(value) - 1) {
            newVal.splice(dialog + 1);
        }

        onChange(newVal);
        closeDialog();
    };

    const valueSize = _.size(value);

    return (
        <>
            <div style={{ display: "flex", direction: "row", alignItems: "baseline" }}>
                {valueSize > Dialogs.Domains && (
                    <Button disabled={disabled} className={classes.filterButton} title={value[Dialogs.Domains].name} onClick={() => setDialog(Dialogs.Domains)}>
                        {value[Dialogs.Domains].name}
                    </Button>
                )}
                {valueSize > Dialogs.DeviceGroups && (
                    <>
                        {" "}
                        <Delim />{" "}
                        <Button disabled={disabled} className={classes.filterButton} title={value[Dialogs.DeviceGroups].name} onClick={() => setDialog(Dialogs.DeviceGroups)}>
                            {value[Dialogs.DeviceGroups].name}
                        </Button>{" "}
                    </>
                )}
                {valueSize > Dialogs.Devices && (
                    <>
                        <Delim />
                        <DeviceButton
                            onClick={() => setDialog(Dialogs.Devices)}
                            devices={value[Dialogs.Devices]}
                            deviceEntities={devices}
                            className={classes.filterButton}
                            title={value[Dialogs.Devices].name}
                            disabled={disabled}
                        />
                    </>
                )}

                {valueSize < Dialogs.Devices + 1 && (
                    <>
                        {" "}
                        {valueSize > 0 && <Delim />}{" "}
                        <Button
                            style={{ marginLeft: 6 }}
                            disabled={disabled}
                            className={classes.allFilterButton}
                            title={formatForId(intl, `GlobalDeviceQueryFilter.${Selection[valueSize]}`)}
                            onClick={() => setDialog(valueSize)}
                        >
                            {formatForId(intl, `GlobalDeviceQueryFilter.${Selection[valueSize]}`)}
                        </Button>{" "}
                    </>
                )}
                {allowUnselectLast && valueSize > 0 && (
                    <Button
                        disabled={disabled}
                        className={classes.allFilterButton}
                        onClick={() => {
                            onChange(_.without(value, value[valueSize - 1]));
                        }}
                        title={formatForId(intl, `GlobalDeviceQueryFilter.clear`)}
                    >
                        <FontAwesomeIcon icon={faRotateLeft} />
                    </Button>
                )}
            </div>

            {dialog === Dialogs.Domains && (
                <SingleEntitySelectionDialog selection={value[Dialogs.Domains]} onSelect={onSelect} open={true} onClose={closeDialog} choices={domains} localizationContext={"DomainSelectionDialog"} />
            )}

            {dialog === Dialogs.DeviceGroups && (
                <SingleEntitySelectionDialog
                    selection={value[Dialogs.DeviceGroups]}
                    onSelect={onSelect}
                    open={true}
                    onClose={closeDialog}
                    choices={deviceGroups}
                    localizationContext={"DeviceGroupSelectionDialog"}
                />
            )}

            {dialog === Dialogs.Devices && (
                <EntitySelectionDialog selection={value[Dialogs.Devices]} onSelect={onSelect} open={true} onClose={closeDialog} choices={devices} localizationContext={"DeviceSelectionDialog"} />
            )}
        </>
    );
}

export function ListField({ localizationContext, value, onChange, component, disabled }) {
    const intl = useIntl();
    const onRowValueChanged = (rowValue, idx) => {
        const newVal = [...value];
        newVal[idx] = rowValue;
        onChange(newVal);
    };

    const onRowAdd = () => {
        onRowValueChanged([], _.size(value));
    };

    const onRowRemove = (idx) => {
        const newVal = [];
        for (let i = 0; i !== value.length; ++i) {
            if (i !== idx) {
                newVal.push(value[i]);
            }
        }

        onChange(newVal);
    };
    return (
        <>
            {_.map(value, (value, idx) => (
                <div key={idx} style={{ display: "flex", flexDirection: "row" }}>
                    {component({ value, idx, onChange: (rowValue) => onRowValueChanged(rowValue, idx) })}
                    <div style={{ display: "flex", flexGrow: 1, alignItems: "center" }}>
                        <Button disabled={disabled} onClick={() => onRowRemove(idx)} title={formatForId(intl, `${localizationContext}.remove`)}>
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </div>
                </div>
            ))}
            <Button disabled={disabled} onClick={onRowAdd} title={formatForId(intl, `${localizationContext}.add`)}>
                <FontAwesomeIcon icon={faPlus} /> <span>&nbsp;</span> {formatForId(intl, `${localizationContext}.add`)}
            </Button>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const SelectFieldsIcons = {
    Numeric: [<FontAwesomeIcon icon={faSortNumericDown} />, <FontAwesomeIcon icon={faSortNumericUp} />],
    Alpha: [<FontAwesomeIcon icon={faSortAlphaDownAlt} />, <FontAwesomeIcon icon={faSortAlphaUpAlt} />],
};
const OrderedFields = [
    { name: "domain.orderName", icons: SelectFieldsIcons.Alpha },
    { name: "deviceGroups.orderName", icons: SelectFieldsIcons.Alpha },
    { name: "deviceDetail.orderName", icons: SelectFieldsIcons.Alpha },
    { name: "deviceDetail.custom_id", icons: SelectFieldsIcons.Numeric },
    { name: "deviceDetail.road", icons: SelectFieldsIcons.Alpha },
    { name: "deviceDetail.road_km", icons: SelectFieldsIcons.Numeric },
    { name: "deviceDetail.road_direction", icons: SelectFieldsIcons.Alpha },
    { name: "deviceDetail.lat", icons: SelectFieldsIcons.Numeric },
    { name: "deviceDetail.lon", icons: SelectFieldsIcons.Numeric },
    { name: "deviceDetail.priority", icons: SelectFieldsIcons.Numeric },
];

function SelectedField({ name, isAscOrder, onChangeSort, disabled, onInsertBefore }) {
    const field = _.find(OrderedFields, ({ name: fieldName }) => fieldName === name) || { icons: SelectFieldsIcons.Numeric };

    const intl = useIntl();
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const toggle = (evt) => {
        setAnchorEl(evt.currentTarget);
        setOpen(!open);
        evt.stopPropagation();
    };

    const close = () => {
        setOpen(false);
    };

    return (
        <div onMouseDown={(evt) => evt.stopPropagation()}>
            {disabled && <Chip disabled={disabled} label={formatForId(intl, `forms.${name}`)} icon={isAscOrder ? field.icons[1] : field.icons[0]} className={classes.chip} />}
            {!disabled && (
                <DraggableChip
                    fieldId="viewOrder"
                    className={classes.chip}
                    label={formatForId(intl, `forms.${name}`)}
                    value={name}
                    icon={isAscOrder ? field.icons[1] : field.icons[0]}
                    onInsertBefore={onInsertBefore}
                    onClick={toggle}
                />
            )}
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={close}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <RadioGroup
                    aria-label="order"
                    name="order"
                    value={isAscOrder ? "+" : "-"}
                    style={{ padding: "1em" }}
                    onChange={(evt) => {
                        onChangeSort(evt.target.value === "+");
                    }}
                >
                    <FormControlLabel value="+" control={<Radio />} label={formatForId(intl, "ordering.asc")} />
                    <FormControlLabel value="-" control={<Radio />} label={formatForId(intl, "ordering.desc")} />
                </RadioGroup>
            </Popover>
        </div>
    );
}

function OrderField({ value, onChange, disabled }) {
    const classes = useStyles();
    const intl = useIntl();
    const theme = useTheme();

    const nameValues = useMemo(() => _.map(value || [], (val) => val.name), [value]);
    const isSortedAscValues = useMemo(() => _.map(value || [], (val) => val.is_asc), [value]);

    const handleChange = (evt) => {
        onChange(
            _.map(evt.target.value, (name, i) => ({
                name,
                is_asc: isSortedAscValues[i] || typeof isSortedAscValues[i] === "undefined",
            }))
        );
    };

    const handleSortChange = (value, idx) => {
        const sortedFields = _.clone(isSortedAscValues);
        sortedFields[idx] = value;
        onChange(_.map(nameValues, (name, i) => ({ name, is_asc: sortedFields[i] })));
    };

    const onInsertBeforeHandler = (subject, dest) => {
        if (subject === dest) return;

        const subjectIndex = _.indexOf(nameValues, subject);

        const newNameItems = _.without(nameValues, subject);
        const newValue = _.without(value, value[subjectIndex]);
        const destIndex = _.indexOf(newNameItems, dest);

        if (subjectIndex <= destIndex) {
            newValue.splice(destIndex, 1, newValue[destIndex], value[subjectIndex]);
        } else {
            newValue.splice(destIndex, 0, value[subjectIndex]);
        }

        onChange(newValue);
    };

    return (
        <FormControl className={classes.formControl}>
            <InputLabel id="order-field">{formatForId(intl, "GlobalDeviceQueryFilter.dataOrder")}</InputLabel>
            <Select
                disabled={disabled}
                labelId="order-field"
                multiple
                value={nameValues}
                onChange={handleChange}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => {
                    const id = selected ? selected.join("+") : "";

                    return (
                        <div className={classes.chips}>
                            {selected.map((value, idx) => (
                                <SelectedField
                                    key={value + "+" + id}
                                    name={value}
                                    isAscOrder={isSortedAscValues[idx]}
                                    onInsertBefore={onInsertBeforeHandler}
                                    onChangeSort={(value) => handleSortChange(value, idx)}
                                />
                            ))}
                        </div>
                    );
                }}
                MenuProps={MenuProps}
            >
                {OrderedFields.map((field) => (
                    <MenuItem disabled={disabled} key={field.name} value={field.name}>
                        {" "}
                        {formatForId(intl, `forms.${field.name}`)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

function OrderSetupField({ filter, value, onChange, disabled }) {
    const by_fields = value ? value.by_fields : [];

    return <OrderField disabled={disabled} value={by_fields} onChange={(value) => onChange({ by_fields: value })} />;
}

/**
 * This component is used to select a domain, device group or device as a filter.
 *
 * @param value
 * @param onChange
 * @param disableDeviceOrdering
 * @constructor
 */

const SimpleFieldDef = {};

export function SimpleDeviceQueryField({ value, onChange, allowUnselectLast, disabled }) {
    return <DeviceFilterRow disabled={disabled} value={value} onChange={onChange} allowUnselectLast={allowUnselectLast} />;
}

export function SimpleOutlinedDeviceQueryField({ value, onChange, allowUnselectLast, disabled, outline, dense }) {
    const [focused, setFocused] = useState(false);

    const rootClassName = outline ? clsx("MuiOutlinedInput-root", focused && "Mui-focused") : null;
    const className = outline ? clsx("DateRangeField-outline", dense && "MuiInputBase-inputMarginDense", dense && "MuiOutlinedInput-inputMarginDense") : null;

    return (
        <div className={rootClassName} onFocus={() => setFocused(true)} onBlur={() => setFocused(false)}>
            <div className={className} style={dense && { paddingTop: 3, paddingBottom: 3 }}>
                <DeviceFilterRow disabled={disabled} value={value} onChange={onChange} allowUnselectLast={allowUnselectLast} />
            </div>
            {outline && <NotchedOutline labelWidth={1} notched={false} className={"MuiOutlinedInput-notchedOutline"} /* style={{top:"-2px"}}*/ />}
        </div>
    );
}

export function GlobalDeviceQueryField({ inputRef, fieldDef, required, fieldId, label, value, changeHandle, touched, errors, type, disabled, autoComplete }) {
    const intl = useIntl();
    const currentValue = value || {
        filter: [],
        order: null,
    };

    const onFilterChange = (filter) => {
        changeHandle({ target: { name: fieldId, value: { ...currentValue, filter } } });
    };

    const onOrderChange = (order) => {
        changeHandle({ target: { name: fieldId, value: { ...currentValue, order } } });
    };

    return (
        <FormControl fullWidth required={!!required} {...fieldDef.props?.formControl} style={{ border: "1px solid rgba(255, 255, 255, 0.23)", borderRadius: 8, marginTop: "1em" }}>
            <ListField
                disabled={disabled}
                localizationContext={"GlobalDeviceQueryFilter"}
                value={currentValue.filter}
                onChange={onFilterChange}
                component={({ value, idx, onChange }) => <DeviceFilterRow disabled={disabled} value={value} onChange={onChange} />}
            />
            {!fieldDef.props?.disableDeviceOrdering && <OrderSetupField disabled={disabled} filter={currentValue.filter} value={currentValue.order} onChange={onOrderChange} />}

            {touched && Boolean(errors) && <FormHelperText error={true}>{errors}</FormHelperText>}
        </FormControl>
    );
}
