import React, { useEffect, useMemo, useRef } from "react";
import { useStore } from "react-redux";
import _ from "loadsh";

import { setCrosshairData } from "../../../../Utils/Data/actions/meteogram";
import { useCrosshairStickSelectionReset } from "../../../../Utils/Data/hooks/meteogram";
import { formatDayTimeShort, formatTime } from "../../../../Utils/Data/Time";
import { ALERT_DEVICE_OFFLINE, ALERT_FORECAST_1, ALERT_FORECAST_2, ALERT_FORECAST_3 } from "../../../../Utils/Data/AlertFormatter";

const STRIPLINE_THICKNESS = 2;

function hideToolbar({ stockChart }) {
    _.each(stockChart?.charts, (chart, idx) => {
        if (idx !== 0) {
            const toolbars = chart?.container.getElementsByClassName("canvasjs-chart-toolbar");
            if (toolbars.length === 1) {
                toolbars[0].style.visibility = "hidden";
            }
        }
    });
}

export function useCanvasJsResizableChartRef() {
    const chartRef = useRef();
    return chartRef;
}

class TimeDataIndex {
    constructor(data) {
        data = data || [];
        this._data = data;
        const index = [];
        for (let i = 0, len = this._data.length; i !== len; ++i) {
            index.push(data[i].time);
        }
        this._index = index;
    }

    findByTime(time, ignoreOlderThan) {
        const data = this._index;
        let min = 0;
        let max = data.length - 1;
        let guess = -1;
        let guessItem = 0;
        while (min <= max) {
            guess = Math.floor((max + min) / 2);
            guessItem = data[guess];

            if (guessItem === time) {
                break;
            } else if (guessItem < time) {
                min = guess + 1;
            } else {
                max = guess - 1;
            }
        }

        if (guess !== -1) {
            if (ignoreOlderThan && Math.abs(time - guessItem) > ignoreOlderThan) return null;

            return this._data[guess];
        } else {
            return null;
        }
    }
}

function findAlerts(selectedTime, items, nowTime) {
    const result = [];

    if (selectedTime >= nowTime) {
        for (let i = 0, len = items ? items.length : 0; i !== len; ++i) {
            const item = items[i];

            const beginTime = item.begin_time;
            const endTime = item.end_time;

            if (beginTime <= selectedTime && ((endTime && selectedTime <= endTime) || !endTime)) {
                result.push(item);
            }
        }
    } else {
        for (let i = 0, len = items ? items.length : 0; i !== len; ++i) {
            const item = items[i];
            //we filter out all forecast for past selection
            if (item.level === ALERT_FORECAST_1 || item.level === ALERT_FORECAST_2 || item.level === ALERT_FORECAST_3) {
                continue;
            }

            const itemEndTime = item.end_time ? item.end_time : Date.now();
            const begin = Math.min(itemEndTime, item.begin_time);
            const end = Math.max(itemEndTime, item.begin_time);

            if (selectedTime >= begin && selectedTime <= end) {
                result.push(item);
            }
        }
    }

    return result;
}

function findAlersUnderCursor(selectedTime, alertHistory, nowTime) {
    if (!alertHistory || !selectedTime) return [];

    const activeAlerts = findAlerts(selectedTime, alertHistory.active, nowTime);
    const notActiveAlerts = findAlerts(selectedTime, alertHistory.notActive, nowTime);

    const allAlerts = [...activeAlerts, ...notActiveAlerts];
    // descending order prepared for grouping
    allAlerts.sort((a, b) => b.level - a.level);

    const groupedByLevel = _.groupBy(allAlerts, ({ level }) => (level === ALERT_FORECAST_1 || level === ALERT_FORECAST_2 || level === ALERT_FORECAST_3 ? level - 10 : level));
    const result = [];
    for (const level in groupedByLevel) {
        result.push({ level: parseInt(level, 10), alerts: groupedByLevel[level] });
    }
    result.sort((a, b) => b.level - a.level);

    return result;
}

class CrosshairRenderer {
    lastPosition = 0;
    currentPosition = 0;
    frameRequested = false;
    keepCurrent = false;
    renderedPosition = 0;
    now = 0;
    ignoreClick = false;
    alertHistory = null;

    constructor(chartRef, store, data, forecastData, intl, theme) {
        this.chartRef = chartRef;
        this.store = store;
        this.data = new TimeDataIndex(data);
        this.forecastData = new TimeDataIndex(forecastData);

        this.boundRender = this.render.bind(this);
        this.intl = intl;
        this.theme = theme;
    }

    onIgnoreNextClick() {
        this.ignoreClick = true;
    }

    onCrosshairClicked(event) {
        if (event && event.target.tagName === "BUTTON") {
            return;
        }

        if (this.ignoreClick) {
            this.ignoreClick = false;
            return;
        }

        if (this.lastPosition === 0) {
            this.keepCurrent = false;
        } else {
            this.keepCurrent = true;
        }
        this.currentPosition = this.lastPosition;
        this.renderedPosition = 0;
        this.requestRender();
    }

    onPositionChanged(ts) {
        if (!this.keepCurrent) {
            this.currentPosition = ts;
        }

        this.lastPosition = ts;
        this.requestRender();
    }

    requestRender(force) {
        if (force) this.renderedPosition = 0;

        if (!this.frameRequested) {
            requestAnimationFrame(this.boundRender);
            this.frameRequested = true;
        }
    }

    renderWithoutViewportChange() {
        const stockChartRef = this.chartRef.current.stockChart;
        for (const chart of stockChartRef.charts) {
            chart._initialize();
            chart.setLayout();
            chart.renderElements();
        }
    }

    render() {
        const stockChartRef = this.chartRef.current;

        const ts = this.lastPosition;
        let selectedDataRow = null;
        let forecastDataRow = null;
        let selectedTime = null;
        if (ts === 0 && !this.keepCurrent) {
            setCrosshairData(
                this.store,
                this.now,
                this.data.findByTime(this.now, 30 * 60 * 1000),
                this.forecastData.findByTime(this.now, 60 * 60 * 1000),
                2,
                findAlersUnderCursor(this.now, this.alertHistory, this.now)
            );

            this.renderedPosition = 0;
            if (!this.keepCurrent) {
                let render = false;

                for (let i = 0, len = stockChartRef.stockChart.options.charts.length; i !== len; ++i) {
                    const axisX = stockChartRef.stockChart.options.charts[i].axisX;
                    if (axisX.originalStripLines && axisX.originalStripLines !== axisX.stripLines) {
                        axisX.stripLines = axisX.originalStripLines;
                        render = true;
                    }
                }

                if (render) this.renderWithoutViewportChange();
            }
        } else if (this.renderedPosition !== this.currentPosition) {
            selectedDataRow = this.data.findByTime(this.currentPosition, 30 * 60 * 1000);
            forecastDataRow = this.forecastData.findByTime(this.currentPosition, 60 * 60 * 1000);
            const alerts = findAlersUnderCursor(ts, this.alertHistory, this.now);
            if (_.first(alerts)?.level === ALERT_DEVICE_OFFLINE) {
                selectedDataRow = null;
            }

            selectedTime = selectedDataRow ? selectedDataRow.time : forecastDataRow ? forecastDataRow.time : ts;
            setCrosshairData(this.store, ts, selectedDataRow, forecastDataRow, this.keepCurrent ? 1 : 0, alerts);
            this.renderedPosition = this.currentPosition;
            let render = false;

            if (this.keepCurrent) {
                for (let i = 0, len = stockChartRef.stockChart.options.charts.length; i !== len; ++i) {
                    const axisX = stockChartRef.stockChart.options.charts[i].axisX;

                    if (axisX.originalStripLines) {
                        axisX.stripLines = axisX.originalStripLines;
                    } else {
                        axisX.originalStripLines = axisX.stripLines || [];
                    }
                    axisX.stripLines = axisX.stripLines ? [...axisX.stripLines] : [];
                    if (i === len - 1) {
                        axisX.stripLines.push({
                            label: "Výber", //FIXME hardcoded
                            value: selectedTime,
                            color: "rgba(0,0,0,0)",
                            labelFontColor: this.theme.palette.historyActiveColor,
                            labelBackgroundColor: this.theme.palette.background.paper,
                            thickness: STRIPLINE_THICKNESS,
                            labelFontSize: 16,
                        });
                    }

                    axisX.stripLines.push({
                        label: i === len - 1 ? formatDayTimeShort(selectedTime) : "",
                        labelPlacement: "outside",
                        labelWrap: false,
                        labelMaxWidth: 500,
                        labelFontColor: "black",
                        color: this.theme.palette.historyActiveColor,
                        value: selectedTime,
                        thickness: STRIPLINE_THICKNESS,
                        lineDashType: "longDashDot",
                        labelBackgroundColor: this.theme.palette.historyActiveColor,
                        labelPadding:
                            i !== len - 1
                                ? { top: 0, bottom: 0, left: 0, right: 0 }
                                : {
                                      top: 2,
                                      bottom: 2,
                                      left: 2,
                                      right: 2,
                                  },
                    });
                }
                render = true;
            } else {
                for (let i = 0, len = stockChartRef.stockChart.options.charts.length; i !== len; ++i) {
                    const axisX = stockChartRef.stockChart.options.charts[i].axisX;
                    if (axisX.originalStripLines && axisX.originalStripLines !== axisX.stripLines) {
                        axisX.stripLines = axisX.originalStripLines;
                        render = true;
                    }
                }
            }

            if (render) this.renderWithoutViewportChange();
        }

        this.frameRequested = false;
        if (stockChartRef) {
            const charts = stockChartRef.stockChart.charts;
            const lastChartIdx = charts.length - 1;

            for (let i = 0; i !== lastChartIdx; ++i) {
                const chart = charts[i];
                if (chart.axisX) {
                    const crosshair = chart.axisX[0]?.crosshair;
                    if (crosshair) {
                        crosshair.showAt(ts);
                    }
                }
            }

            const chart = charts[lastChartIdx];
            if (chart.axisX) {
                const crosshair = chart.axisX[0]?.crosshair;
                if (crosshair) {
                    crosshair.label = !selectedDataRow ? "" : formatTime(ts, true);
                    crosshair.showAt(ts);
                }
            }
        }
    }
}

export function useCrosshair(chartRef, now, data, forecastData, columns, alertData, intl, theme) {
    const store = useStore();
    const [onCrosshairChanged, onCrosshairClicked, onRender, onIgnoreNextClick, renderer] = useMemo(() => {
        const result = new CrosshairRenderer(chartRef, store, null, null, intl, theme);
        return [result.onPositionChanged.bind(result), result.onCrosshairClicked.bind(result), result.requestRender.bind(result), result.onIgnoreNextClick.bind(result), result];
    }, []);

    const stickSelectionReset = useCrosshairStickSelectionReset();
    useEffect(() => {
        renderer.keepCurrent = false;
        renderer.now = now;
        renderer.requestRender(true);
    }, [now, stickSelectionReset]);
    useEffect(() => {
        renderer.data = new TimeDataIndex(data);
        onRender(true);
    }, [data]);

    useEffect(() => {
        renderer.forecastData = new TimeDataIndex(forecastData);
        onRender(true);
    }, [forecastData]);

    useEffect(() => {
        onRender(true);
    }, [columns]);

    useEffect(() => {
        renderer.alertHistory = alertData;
        onRender(true);
    }, [alertData]);

    useEffect(() => {
        renderer.intl = intl;
        onRender(true);
    }, [intl]);

    return [onCrosshairChanged, onCrosshairClicked, onIgnoreNextClick, onRender];
}

export function useCrossHairViewOptions(theme, options, nowValue, nowLabel, onCrosshairChanged, onCrosshairClicked, onIgnoreNextClick) {
    let result = useMemo(() => {
        for (let i = 0, len = options.charts.length; i !== len; ++i) {
            const chart = options.charts[i];
            chart.rangeChanged = onIgnoreNextClick;

            const axisX = chart.axisX;
            axisX.crosshair = {
                updated: (e) => {
                    onCrosshairChanged(e.value);
                },
                hidden: (e) => onCrosshairChanged(0),

                enabled: true,
                snapToDataPoint: false,
                thickness: STRIPLINE_THICKNESS,
                color: "white",
                lineDashType: "longDashDot",
                label: "",
            };
            if (!axisX.stripLines) axisX.stripLines = [];

            if (i === len - 1) {
                axisX.stripLines.push({
                    label: formatDayTimeShort(nowValue),
                    value: nowValue,
                    thickness: STRIPLINE_THICKNESS,
                    lineDashType: "longDashDot",
                    color: "#00B0F0",
                    labelBackgroundColor: "#00B0F0",
                    labelFontColor: "black",
                    labelPlacement: "outside",
                    labelWrap: false,
                    labelMaxWidth: 500,
                });

                axisX.stripLines.push({
                    label: nowLabel,
                    value: nowValue,
                    thickness: STRIPLINE_THICKNESS,
                    lineDashType: "longDashDot",
                    color: "rgba(0,0,0,0)",
                    labelFontColor: "#00B0F0",
                    labelBackgroundColor: theme.palette.background.paper,
                    labelFontSize: 16,
                });
            } else {
                axisX.stripLines.push({
                    label: "",
                    value: nowValue,
                    thickness: STRIPLINE_THICKNESS,
                    lineDashType: "longDashDot",
                    color: "#00B0F0",
                    labelBackgroundColor: "transparent",
                    labelFontColor: "black",
                });
            }
        }

        const mainAxisX = options.charts[options.charts.length - 1].axisX;

        mainAxisX.crosshair.labelFormatter = (e) => {
            return formatDayTimeShort(e.value);
        };

        return options;
    }, [options, onCrosshairChanged, onCrosshairClicked]);
    return result;
}
