import React, { useEffect, useMemo } from "react";
import { IconButton, Slider, withStyles } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPause, faPlay } from "@fortawesome/pro-solid-svg-icons";
import { makeStyles } from "@material-ui/core/styles";
import _ from "underscore";

const CustomSlider = withStyles({
    thumb: {
        height: 10,
        width: 10,
        backgroundColor: "gray",
        border: "3px solid #fff",
        marginTop: -2,
        "&:focus, &:hover, &$active": {
            boxShadow: "inherit",
        },
    },
    track: {
        marginTop: 2,
        height: 3,
        borderRadius: 4,
        backgroundColor: "whitesmoke",
    },
    rail: {
        marginTop: 2,
        height: 3,
        borderRadius: 4,
        backgroundColor: "whitesmoke",
    },
    mark: {
        height: 6,
        width: 2,
        backgroundColor: "black",
        borderRadius: 4,
        border: "1px solid whitesmoke",
    },
    markLabel: {
        paddingTop: 6,
    },
})(Slider);

const useStyles = makeStyles((theme) => ({
    infoRoot: {
        height: "100%",
        display: "flex",
        alignItems: "center",
        width: "100%",
    },
    slider: {
        flex: 1,
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(1.5),
    },
    infoControl: {
        marginLeft: "auto",
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        minWidth: 60,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
    },
}));

export function PlayerSlider({ value, values, onChange, paused, onPause, labelFormatter, labelFilterFactor = 12, showMarks = false, showPause = false, showValue = false, onDisplayValue }) {
    const classes = useStyles();

    const valComputed = useMemo(() => {
        const valIdx = values.findIndex((val) => val.value === value);
        if (valIdx !== -1) {
            return {
                idx: valIdx,
                label: labelFormatter(values[valIdx]),
            };
        }

        return { idx: valIdx, label: "" };
    }, [value]);

    useEffect(() => onDisplayValue && onDisplayValue(valComputed.label), [valComputed]);

    const marks = useMemo(() => {
        const factor = Math.round(values.length / labelFilterFactor);

        return values.map((val, idx) => ({
            value: idx,
            label: (idx % factor === 0 || idx === values.length - 1) && showMarks ? labelFormatter(val, idx) : "",
            originalValue: val,
        }));
    }, [values]);

    if (_.isEmpty(marks)) {
        return <></>;
    }

    return (
        <div className={classes.infoRoot}>
            {showPause && (
                <div className={classes.infoControl}>
                    <IconButton>
                        <FontAwesomeIcon icon={paused ? faPlay : faPause} size={"1x"} onClick={onPause} />
                    </IconButton>
                </div>
            )}
            <div className={classes.slider}>
                <CustomSlider
                    value={valComputed.idx}
                    min={0}
                    max={marks.length - 1}
                    marks={marks}
                    onChange={(e, idx) => {
                        onChange(values[idx]?.value);
                    }}
                />
            </div>
            {!showMarks && showValue && <div className={classes.valueInfo}>{valComputed.label}</div>}
        </div>
    );
}
