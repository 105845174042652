import React, { useMemo } from "react";
import { CloudFog, Rain1, Rain2, Rain3, RainSnowMix, RoadSnow1, RoadSnow2, RoadSnow3, RoadWet1, RoadWet2, RoadWet3, Snow1, Snow2, Snow3, Wind } from "../../Components/Icons/PictogramIcons";
import { useIntl } from "react-intl";
import { formatForId } from "../Lang/IntlHelper";
import _ from "loadsh";

function getRoad(device, deviceState, intl) {
    let icon = <div />;
    let tooltipId = -1;

    if (device.driver === "znp-device") {
        const roadStatusVal = deviceState.data?.meteo_v1?.its_road_surface?.value; //270

        if (roadStatusVal) {
            switch (roadStatusVal) {
                case 1:
                    tooltipId = 1;
                    icon = <RoadWet1 />;
                    break;
                case 2:
                    tooltipId = 2;
                    icon = <RoadWet2 />;
                    break;
                case 3:
                    tooltipId = 3;
                    icon = <RoadWet3 />;
                    break;
                case 4:
                case 6:
                    tooltipId = 9;
                    break;
                case 5:
                    tooltipId = 6;
                    icon = <RoadSnow1 />;
                    break;
            }
        }
    } else {
        const roadStatusVal = deviceState.data?.meteo_v1?.road_status?.value?.value; //70
        const roadSurfaceVal = deviceState.data?.meteo_v1?.road_surface?.value?.value; //124

        if (roadStatusVal) {
            switch (roadStatusVal) {
                case 1:
                    icon = <RoadWet1 />;
                    tooltipId = 1;
                    break;
                case 32: {
                    switch (roadSurfaceVal) {
                        case 2:
                            icon = <RoadWet2 />;
                            tooltipId = 2;
                            break;
                        case 3:
                        case 4:
                            icon = <RoadWet3 />;
                            tooltipId = 3;
                            break;
                    }
                    break;
                }
                case 64:
                    icon = <RoadSnow1 />;
                    tooltipId = 5;
                    break;
                case 65:
                    icon = <RoadSnow2 />;
                    tooltipId = 6;
                    break;
                case 66:
                    icon = <RoadSnow3 />;
                    tooltipId = 7;
                    break;
                case 67:
                    icon = <RoadSnow3 />;
                    tooltipId = 8;
                    break;
            }
        }
    }

    return { icon, tooltip: tooltipId === -1 ? "" : formatForId(intl, `pictogram.road.tooltip.${tooltipId}`) };
}

export function usePictograms(device, deviceState) {
    const intl = useIntl();

    return useMemo(() => {
        let ret = {
            precip: { icon: <div />, tooltip: "" },
            wind: { icon: <div />, tooltip: "" },
            road: getRoad(device, deviceState, intl),
            isRain: false,
            isSnow: false,
        };

        if (isWindAlertActive(deviceState.data?.meteo_v1?.wind_speed?.value?.value)) {
            ret.wind.icon = Wind();
            ret.wind.tooltip = formatForId(intl, "pictogram.wind.activeTooltip");
        }

        const precipVal = deviceState.data?.meteo_v1?.precip_type?.value;
        let wmoCode = 0;
        if (precipVal) {
            wmoCode = precipVal.value;
        } else if (device.driver === "boschung-device" || device.driver === "xml_import_driver" || device.driver === "testing-device") {
            const precipIntensVal = deviceState.data?.meteo_v1?.precip_intens?.value;

            if (precipIntensVal) {
                const hasXflagS = deviceState.active_warnings?.find((wrn) => wrn.type === "boschung.S");

                switch (precipIntensVal.value) {
                    case 1:
                    case 2:
                        if (hasXflagS) {
                            wmoCode = 71;
                        } else {
                            wmoCode = 61;
                        }
                        break;
                    case 3:
                        if (hasXflagS) {
                            wmoCode = 72;
                        } else {
                            wmoCode = 62;
                        }
                        break;
                }
            }
        }

        if (wmoCode > 0) {
            const precipLevel = getPrecipLevel(wmoCode);
            ret.precip.icon = getPrecipIcon(precipLevel);
            ret.precip.tooltip = formatForId(intl, `value.WMOcode.${wmoCode}`);
            ret.isRain = isRain(precipLevel);
            ret.isSnow = isSnow(precipLevel);
        }

        return ret;
    }, [device, deviceState]);
}

export function useHasPictogram(device, deviceState) {
    const pictograms = usePictograms(device, deviceState);
    return useMemo(() => !_.isEmpty(pictograms.road.tooltip) || !_.isEmpty(pictograms.wind.tooltip) || !_.isEmpty(pictograms.precip.tooltip), [pictograms]);
}

export function getPrecipLevel(precipLevel) {
    switch (
        precipLevel //WMO code
    ) {
        case undefined:
        case 0:
            return "none";
        case 10:
        case 30:
            return "mist";
        case 41:
        case 51:
        case 52:
        case 61:
            return "rain_1";
        case 53:
        case 62:
            return "rain_2";
        case 47:
        case 67:
        case 68:
            return "mix";
        case 63:
            return "rain_3";
        case 71:
            return "snow_1";
        case 72:
        case 77:
            return "snow_2";
        case 73:
        case 78:
        case 79:
            return "snow_3";
        default:
            return "none";
    }
}

export function isWindAlertActive(windSpeed) {
    return windSpeed && windSpeed >= 10.7;
}

function getPrecipIcon(precipLevel) {
    switch (precipLevel) {
        case "none":
            return <div />;
        case "rain_1":
            return Rain1();
        case "rain_2":
            return Rain2();
        case "rain_3":
            return Rain3();
        case "snow_1":
            return Snow1();
        case "snow_2":
            return Snow2();
        case "snow_3":
            return Snow3();
        case "mist":
            return CloudFog();
        case "mix":
            return RainSnowMix();
        default:
            return <div />;
    }
}

function isRain(precipLevel) {
    return precipLevel === "rain_1" || precipLevel === "rain_2" || precipLevel === "rain_3";
}

function isSnow(precipLevel) {
    return precipLevel === "snow_1" || precipLevel === "snow_2" || precipLevel === "snow_3" || precipLevel === "mix";
}
