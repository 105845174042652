import React from "react";
import {DEFAULT_DATA_FIELDS_ORDER} from "../Utils/Data/ViewsDefinitions";
import ActionToolbar from "../Components/MasterDetail/ActionToolbar";
import DialogForm from "../Components/Forms/DialogForm";
import {Card, CardActions, CardContent} from "@material-ui/core";
import _ from "loadsh";
import TimeZones from "../Utils/Lang/TimeZones";
import moment from "moment-timezone";
import {formatForId} from "../Utils/Lang/IntlHelper";
import {useIntl} from "react-intl";
import LoadingScope from "../Components/LoadingScope";
import {getMeteoV1} from "../Utils/Data/ValueMapper";

const dataFields = _.clone(DEFAULT_DATA_FIELDS_ORDER);
dataFields.shift();

const filenameFormats = ["{road_name}{road_km}{name}", "{name}", "{custom_id}{name}"]
const formConfig =
    [
        {
            id: "dateRange", type: "DateRangeField",

        },
        {
            type: "Group",
            fields: [
                {
                    id: "time",
                    type: "DropdownField",
                    props: {
                        multiple: false,
                        items: ["formatted_iso_8601", "unix_seconds", "unix_milliseconds"],
                        localizationContext: "forms.deviceExport",
                    },
                    defaultValue: ["formatted_iso_8601"]

                },
                {
                    id: "time_zone",
                    type: "DropdownField",
                    props: {
                        multiple: false,
                        items: TimeZones,
                    },
                    defaultValue: ["Europe/Bratislava"]

                },
                {
                    id: "filename_format",
                    type: "DropdownField",
                    props: {
                        multiple: false,
                        items: filenameFormats,
                        localizationContext: "forms.deviceExport.filename_format_items",
                    },
                    defaultValue: filenameFormats[0]

                },

            ]
        },
        {
            type: "Group",
            fields: [
                {
                    id: "csv_delimiter",
                    type: "DropdownField",
                    props: {
                        multiple: false,
                        items: [";", ","],
                    },
                    defaultValue: [";"]
                },
                {
                    id: "decimal_point",
                    type: "DropdownField",
                    props: {
                        multiple: false,
                        items: [".", ","],
                    },
                    defaultValue: [","]
                },
                {
                    id: "header_type",
                    type: "DropdownField",
                    props: {
                        multiple: false,
                        items: ["abrv", "full"],
                        localizationContext: "forms.deviceExport.header_type_items",
                    },
                    defaultValue: ["abrv"]
                },
                {
                    id: "header_type_display",
                    type: "DropdownField",
                    props: {
                        multiple: true,
                        items: ["id", "units"],
                        localizationContext: "forms.deviceExport.header_type_display_items",
                    },
                    defaultValue: null,
                }
            ]
        },
        {
            id: "data_fields",
            type: "DropdownField",
            props: {
                allowChangeOrder: true,

                multiple: true,
                items: dataFields,
                localizationContext: "value.meteo_v1.name",
            },
        },
        {
            id: "data_query",
            type: "QueryField",
            props: {
                disableDeviceOrdering: true
            },

        }
    ];

function createRequest({
                           dateRange,
                           time,
                           time_zone,
                           data_fields,
                           data_query,
                           csv_delimiter,
                           decimal_point,
                           header_type,
                           header_type_display,
                           filename_format
                       }, intl) {
    if (_.isEmpty(data_query)) {
        return {errors: [{id: "data_query", message: "T:formField.required", tokens: []}]}
    }

    let {begin, end} = dateRange;
    if (!end) {
        end = moment();
    }
    if (end.isBefore(begin)) {
        const tmp = end;
        end = begin;
        begin = tmp;
    }
    begin = begin.tz(time_zone[0]);
    end = end.tz(time_zone[0]);

    if (dateRange.type === "year") {
        begin.startOf("year");
        end = begin.clone().add(1, "year");
    } else if (dateRange.type === "year_month") {
        begin.startOf("month");
        end = begin.clone().add(1, "month");
    } else {
        begin.startOf("day");
        end.add(1, "day").startOf("day");
    }

    const query = {...data_query};
    query.include_disabled = true;

    const fields = (() => {
        const fields = _.isEmpty(data_fields) ? dataFields : data_fields;

        const result = ["time"];
        for (const f of fields)
            result.push(f);

        return result;
    })();

    const meteoV1 = getMeteoV1();
    const shouldDisplayUnits = header_type_display && header_type_display.indexOf("units") >= 0;
    const shouldDisplayId = header_type_display && header_type_display.indexOf("id") >= 0;

    const format_fields = () => {
        return fields.map(field => {
            let value = formatForId(intl, `value.meteo_v1.${header_type[0] == "abrv" ? "shortcut" : "name"}.${field}`);

            if (shouldDisplayUnits || shouldDisplayId) {
                const fieldSpec = meteoV1[field];
                if (shouldDisplayUnits && fieldSpec && fieldSpec.unit) {
                    value = value + " [" + formatForId(intl, `value.unit.${fieldSpec.unit}`) + "]";
                }

                if (shouldDisplayId && fieldSpec && fieldSpec.nopeId) {
                    value = value + ` [ID=${fieldSpec.nopeId}]`;
                }
            }

            return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        });


    }

    return {
        begin_time: begin.valueOf(),
        end_time: end.valueOf(),
        data_store: "meteo_v1",
        columns: fields,
        time_zone: time_zone[0],
        time_format: time[0],
        csv_delimiter: csv_delimiter[0],
        decimal_point: decimal_point[0],
        query: query,
        localized_columns_names: format_fields(),
        formatter: filename_format
    };
}

function getFileName({dateRange, data_query}, contentType) {
    if (contentType === "application/zip" || contentType === "application/octet-stream") {
        return moment().format("YYYY-MM-DD_hhmmss") + ".zip";
    } else {
        return "error.txt";
    }
}

export function DeviceDataExport() {
    const actions = [
        {
            id: "exportData",
        }
    ];
    const intl = useIntl();
    const [loading, setLoading] = React.useState(false);

    const onSubmit = async (values, context) => {
        const request = createRequest(values, intl);
        if (!_.isEmpty(request.errors)) {
            context.setErrors(request.errors);
            return;

        }

        setLoading(true);
        const response = await fetch("/api/history/export/", {
            method: 'POST',
            body: JSON.stringify(request),
            headers: {
                "content-type": "application/json"
            },
            cache: "no-cache",
            keepalive: false,
        });


        const contentType = response.headers.get('content-type');

        const blob = await response.blob();


        var a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = getFileName(values, contentType);
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();  //afterwards we remove the element again

        window.URL.revokeObjectURL(a.href);
        setLoading(false);

    };

    return (<LoadingScope loading={loading} dialog={true}>
        <Card style={{overflow: "unset"}}>

            <CardContent>
                <DialogForm
                    submitEventId={"exportData"}
                    timeOpened={0}
                    formConfig={formConfig}
                    formData={{}}
                    onSubmit={onSubmit}
                    formId={"deviceExport"}/>
                <CardActions>
                    <ActionToolbar localizationContext="pages.deviceExport" actions={actions} detailToolbar={true}
                                   buttonProps={{variant: "outlined"}}/>
                </CardActions>
            </CardContent>

        </Card>

    </LoadingScope>);
}