import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
    FormControl,
    FormHelperText,
    FormLabel,
    MenuItem,
    Select,
    Checkbox,
    Typography,
    FormControlLabel
} from "@material-ui/core";
import {formatForId} from "../../Utils/Lang/IntlHelper";
import {useIntl} from "react-intl";
import _ from "lodash"
import {Label} from "@material-ui/icons";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export function CheckForm({
                              errors,
                              fieldId,
                              value,
                              required,
                              localizationContext,
                              changeHandle,
                              fieldDef
                          }) {
    const intl = useIntl();
    const items = fieldDef.props.items || [];

    React.useEffect(() => {
        console.log("value changed", value);
    }, [value]);

    const onChange = (id, checked) => {
        let eventObj = {
            target: {
                value: null, name: fieldId
            }
        };
        if (!checked) {
            eventObj.target.value = _.without(value, id);
        } else {
            const clone = _.clone(value)
            clone.push(id);
            eventObj.target.value = clone;
        }

        changeHandle(eventObj);
        console.log(eventObj);
    }

    const groups = useMemo(() => _.groupBy(items, (item) => item.split("__")[0]), [items]);


    return (
        <FormControl fullWidth required={required} error={errors}
                     style={errors ? {border: "1px solid red", borderRadius: "10px"} : {}}>
            {errors && <FormHelperText>
                {errors}
            </FormHelperText>}
            {_.map(groups, (items, key) => (
                <div key={key}>
                    <Typography component={"h3"}>  {formatForId(intl, localizationContext + "." + key)} </Typography>
                    {
                        _.map(items, item => (
                            <FormControlLabel key={item}
                                              control={<Checkbox defaultChecked={_.indexOf(value, item) !== -1}
                                                                 onChange={(e) => onChange(item, e.target.checked)}/>}
                                              label={formatForId(intl, localizationContext + "." + item)}/>


                        ))
                    }

                </div>))}
        </FormControl>

    );
}