import React, { useEffect } from "react";
import { useLeafletContext } from "@react-leaflet/core";
import L from "leaflet";
import "../../Components/Map/Nira/VectorGrid/Leaflet.VectorGrid.Protobuf";
import "../../Components/Map/Nira/VectorGrid/Leaflet.Renderer.Canvas.Tile";
import "../../Components/Map/Nira/VectorGrid/Leaflet.Renderer.SVG.Tile";

import { setNiraDataPanelEnabled } from "../../Utils/Data/actions/map";
import { useStore } from "react-redux";
import { useGradient } from "../../Components/Map/Nira/Gradient";
import LoadingScope from "../../Components/LoadingScope";
import { NiraUrl, RoughnessChangesAlerts, useRoughnessAlertsInterval } from "../../Components/NiraApi";
import * as ReactDOMServer from "react-dom/server";
import SegmentPopup, { AlertPopup } from "../../Components/Map/Nira/VectorGrid/SegmentPopup";
import { useIntl } from "react-intl";
import { NiraArrowDownIcon, NiraArrowUpIcon } from "../../Components/Icons/NiraIcons";
import { useInsufficientNiraZoom, useSelectedRoughnessDataTime } from "../../Utils/Data/hooks/map";
import moment from "moment";

const weightSettings = {
    18: {
        default: 6,
    },
    17: {
        default: 6,
    },
    16: {
        default: 4,
    },
    15: {
        default: 4,
    },
    14: {
        default: 3,
    },
    default: {
        1: 3,
        2: 3,
        3: 2.5,
        4: 2.5,
        5: 2.5,
    },
};

function getWeight(zoom, segmentClass) {
    if (weightSettings[zoom]) {
        if (weightSettings[zoom][segmentClass]) {
            return weightSettings[zoom][segmentClass];
        } else return weightSettings[zoom].default;
    } else if (weightSettings.default[segmentClass]) {
        return weightSettings.default[segmentClass];
    } else return 3;
}

const IconSize = 20;
const IconAnchor = IconSize / 2;

const IncreaseIcon = {
    icon: L.icon({
        iconUrl: NiraArrowDownIcon,
        iconSize: [IconSize, IconSize],
        iconAnchor: [IconAnchor, IconAnchor],
        popupAnchor: [0, 0],
    }),
};

const DecreaseIcon = {
    icon: L.icon({
        iconUrl: NiraArrowUpIcon,
        iconSize: [IconSize, IconSize],
        iconAnchor: [IconAnchor, IconAnchor],
        popupAnchor: [0, 0],
    }),
};

const emptyStyle = { opacity: 0 };

export default function VectorGrid({ niraData }) {
    const { layerContainer, map } = useLeafletContext();
    const store = useStore();
    const url = NiraUrl + "/tiles/?z={z}&x={x}&y={y}&geometry_model_id=" + niraData.geometry_model_id;
    const intl = useIntl();
    const { startDate, endDate } = useRoughnessAlertsInterval(niraData);

    const { loading: niraDataLoading, dataType, dataTs, roughnessChangesAlertsLastDataTime } = niraData;
    const { gradientGetter } = useGradient(dataType);
    const insufficientZoom = useInsufficientNiraZoom();

    const getOptions = (properties, zoom, segmentClass) => {
        if (dataType !== "none" && !niraDataLoading && !insufficientZoom) {
            const val = properties.niraData;
            if (val !== undefined) {
                return {
                    color: gradientGetter(val.value),
                    opacity: 1,
                    stroke: true,
                    lineCap: "butt",
                    weight: getWeight(zoom, segmentClass),
                };
            }
        }
        return emptyStyle;
    };

    useEffect(() => {
        if (niraDataLoading) {
            return;
        }

        const options = {
            rendererFactory: dataType === RoughnessChangesAlerts ? L.svg.tile : L.canvas.tile,
            interactive: true,
            getFeatureId: (item) => {
                return item.properties.segment_id + "-" + item.properties.sub_segment_id;
            },
            vectorTileLayerStyles: {
                1: (properties, zoom) => getOptions(properties, zoom, 1),
                2: (properties, zoom) => getOptions(properties, zoom, 2),
                3: (properties, zoom) => getOptions(properties, zoom, 3),
                4: (properties, zoom) => getOptions(properties, zoom, 4),
                5: (properties, zoom) => getOptions(properties, zoom, 5),
                roughness_alert_increase: insufficientZoom ? emptyStyle : IncreaseIcon,
                roughness_alert_decrease: insufficientZoom ? emptyStyle : DecreaseIcon,
            },
            niraDataType: dataType,
            dataTimestamp: dataTs,
            filter: dataType === RoughnessChangesAlerts ? (properties) => properties.time_start > startDate && properties.time_start < endDate : undefined,
        };

        let tooltip;
        const vectorGrid = L.vectorGrid.protobuf(url, options);

        const container = layerContainer || map;

        L.DomEvent.fakeStop = function () {
            return true;
        };

        let highlight = [];
        const clearHighlight = () => {
            if (highlight.length > 0) {
                for (const item of highlight) {
                    vectorGrid.resetFeatureStyle(item);
                }
            }
            highlight = [];

            if (tooltip) {
                tooltip.close();
            }
        };

        const getData = (e) => {
            return e.layer.properties.niraData;
        };

        vectorGrid.on("click", (e) => {
            if (!getData(e)) {
                return;
            }
            setNiraDataPanelEnabled(store, true);
            clearHighlight();
        });

        vectorGrid.on("mouseover", (e) => {
            clearHighlight();
            const data = getData(e);
            if (data) {
                const selectedNiraSegment = {
                    segmentId: e.layer.properties.segment_id,
                    subSegmentId: e.layer.properties.sub_segment_id,
                    data: e.layer.properties.niraData,
                };

                for (let i = data.subsegmentMapping[0]; i <= data.subsegmentMapping[1]; i++) {
                    const highlightItem = e.layer.properties.segment_id + "-" + i;
                    vectorGrid.setFeatureStyle(highlightItem, { ...e.layer.options, weight: 10, color: "#808080" });
                    highlight.push(highlightItem);
                }

                if (tooltip) {
                    tooltip.close();
                }

                tooltip = L.tooltip()
                    .setLatLng(e.latlng)
                    .setContent(ReactDOMServer.renderToString(<SegmentPopup selectedData={dataType} niraData={niraData} selectedNiraSegment={selectedNiraSegment} intl={intl} dataType={dataType} />))
                    .addTo(map);
            } else if (e.layer.properties.alert_roughness !== undefined) {
                const alertData = e.layer.properties;
                if (tooltip) {
                    tooltip.close();
                }

                tooltip = L.tooltip()
                    .setLatLng(e.latlng)
                    .setContent(ReactDOMServer.renderToString(<AlertPopup alertData={alertData} intl={intl} />))
                    .addTo(map);
            }
        });

        vectorGrid.on("mouseout", (e) => clearHighlight());

        container.addLayer(vectorGrid);

        return () => {
            container.removeLayer(vectorGrid);
            if (tooltip) {
                tooltip.close();
            }
        };
    }, [dataType, niraDataLoading, url, dataTs, insufficientZoom, startDate, endDate]);

    return (
        <LoadingScope loading={niraDataLoading} dialog>
            <div />
        </LoadingScope>
    );
}
