import * as React from "react";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import { formatForId } from "../../Utils/Lang/IntlHelper";
import { useIntl } from "react-intl";

export default function RadioGroupField({ inputRef, fieldId, value, required, changeHandle, touched, errors, label, disabled, fieldDef }) {
    const intl = useIntl();
    const items = fieldDef.props.items || [];
    const localizationContext = fieldDef.props.localizationContext;

    const valueLabel = (value) => (localizationContext ? formatForId(intl, localizationContext + "." + value) : value);

    return (
        <FormControl required={required}>
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup style={{ flexDirection: "row" }} name={fieldId} onChange={changeHandle} error={touched && Boolean(errors)} value={value ? value : items?.[0]} inputRef={inputRef}>
                {items.map((item) => (
                    <FormControlLabel value={item} key={item} control={<Radio />} label={valueLabel(item)} disabled={disabled} />
                ))}
            </RadioGroup>
        </FormControl>
    );
}
