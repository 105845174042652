import React, { useMemo } from "react";
import { IconButton, Tooltip, useTheme } from "@material-ui/core";
import { useIntl } from "react-intl";
import Badge from "@material-ui/core/Badge";
import { useActiveAlertCounter, useIsHistoryActive } from "../../Utils/Data/hooks/server";
import { makeStyles } from "@material-ui/styles";
import { formatForId } from "../../Utils/Lang/IntlHelper";
import { getAlertColor } from "../../Utils/Data/AlertFormatter";
import useWarningSound from "../WarningAlertPlayer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons";

const useStyles = makeStyles({
    badge: {
        backgroundColor: (props) => props.color.primary,
        color: (props) => props.color.textColor,
    },
});

export function ActiveAlerts({ onClick }) {
    const intl = useIntl();
    const warnings = useActiveAlertCounter();
    const theme = useTheme();

    const color = useMemo(() => getAlertColor(warnings.maxLevel, theme), [warnings]);

    const classes = useStyles({ color });

    useWarningSound();

    const historyActive = useIsHistoryActive();

    return (
        <Tooltip title={formatForId(intl, "pages.appBar.tooltip.alerts")}>
            <IconButton onClick={onClick}>
                <Badge badgeContent={warnings.notConfirmedActiveAlerts} classes={classes}>
                    <FontAwesomeIcon icon={faCircleExclamation} color={historyActive ? theme.palette.historyActiveColor : ""} />
                </Badge>
            </IconButton>
        </Tooltip>
    );
}
