import React from 'react';
import {useParams} from "react-router-dom";
import MasterDetailView from "../../../Components/MasterDetail/MasterDetailView";
import MasterDetailContextMenu from "../../../Components/MasterDetail/MasterDetailContextMenu";
import {DetailContextMenu} from "../../../Components/MasterDetail";
import ActionToolbar from "../../../Components/MasterDetail/ActionToolbar";
import MasterDetailTable from "../../../Components/MasterDetail/MasterDetailTable";
import Button from "@material-ui/core/Button";
import {useIntl} from "react-intl";
import _ from "loadsh";
import {useSafeOpenDetail} from "../../../Utils/Data/hooks/masterDetail";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDatabase} from "@fortawesome/pro-solid-svg-icons";
import DeviceDetail from "./DeviceDetail";
import {DriverInterfaces} from "./DeviceDetail.form";
import {useGetInMemoryDataList} from "../../../Utils/Data/hooks/dataList";


export default function DataSources() {
    const {id} = useParams();
    const intl = useIntl();

    const domainId = parseInt(id, 10);
    const safeOpenDetail = useSafeOpenDetail();

    const columns = [
        {
            field: 'name',
            width: 150,
            sortable: true,
            renderCell: (params) => <Button><FontAwesomeIcon icon={faDatabase}/>&nbsp; {params.row.name}</Button>
        },
    ];


    const dataSource = useGetInMemoryDataList({
        url: "domain/" + domainId + "/device-group/list/",
        defaultSort: {field: "name", isAscending: true},
        preprocessData: data => _.map(_.find(data, group => group.id < 0)?.devices, device => {
            return {...device, name: device.name.substring(0, device.name.length - 3)};
        }) || []
    });

    return <MasterDetailView loading={dataSource.loading} loadingError={dataSource.error}
                             onRenderContextMenu={({isDetailOpened, viewMode}) =>
                                 <MasterDetailContextMenu isDetailOpened={isDetailOpened} viewMode={viewMode}
                                                          onRenderDetailMenu={() => <DetailContextMenu
                                                              onRenderBeforeLabel={() => <FontAwesomeIcon
                                                                  icon={faDatabase}/>}/>}
                                                          onRenderMasterMenu={() => <ActionToolbar
                                                              localizationContext={"pages.dataSources"}
                                                              actions={[{
                                                                  id: "create",
                                                                  onClick() {
                                                                      safeOpenDetail("dataSource", {
                                                                          isNew: true,
                                                                          domain_id: domainId,
                                                                          group_id: -1 * domainId
                                                                      });
                                                                  }
                                                              }]} searchFieldName={"name"}/>}/>}
                             onRenderDetail={() => <DeviceDetail
                                 driverInterface={DriverInterfaces.MasterMeteoDevice}/>}>

        <MasterDetailTable dataSource={dataSource}
                           entityType={"dataSource"}
                           columns={columns}

        />
    </MasterDetailView>
}