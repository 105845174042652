import {useIntl} from "react-intl";
import {formatForId} from "./Utils/Lang/IntlHelper";
import {Store} from "react-notifications-component";
import 'react-notifications-component/dist/theme.css'

export function useNotification() {
    const intl = useIntl();

    const showMessageInternal = (message, variant) => {
        Store.addNotification({
            message: message,
            type: variant,
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true,
            },
        });
    }

    return {
        showMessage(variant, message, ...params) {
            const localizedMessage = formatForId(intl, message, params);
            showMessageInternal(localizedMessage, variant);
        },
        showRawMessage(variant, message, ...params) {
            showMessageInternal(message, variant);
        },
        showSuccess(message, ...params) {
            this.showMessage("success", message, params);
        },
        showError(message, ...params) {
            this.showMessage("error", message, params);
        },
        async showApiMessage(promise) {
            const response = await promise;
            const {status, body} = response;
            let variant;
            if (status >= 200 && status < 300) {
                variant = "success";
            } else {
                variant = "error";
            }

            if (body && body.hasOwnProperty("message")) {
                const {message} = body;
                const parts = message.split(/T[:](.+)/)
                if (parts.length === 3) {
                    this.showMessage(variant, parts[1])
                } else {
                    this.showRawMessage(variant, message);
                }
            } else {
                this.showRawMessage(variant, "No response body!!!");
            }

            return response;
        }
    };
}