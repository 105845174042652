import { Checkbox, FormControl, FormControlLabel, FormLabel } from "@material-ui/core";
import React, { useMemo } from "react";

export function CheckBoxField({ inputRef, fieldId, value, changeHandle, disabled, label }) {
    const valueComputed = useMemo(() => (typeof value === "string" ? value === "true" : value), [value]);

    return (
        <FormControl fullWidth>
            <FormLabel component="legend">{label}</FormLabel>
            <FormControlLabel
                style={{ padding: "0 5px" }}
                control={<Checkbox inputRef={inputRef} name={fieldId} disabled={disabled} value={Boolean(valueComputed)} checked={Boolean(valueComputed)} onChange={changeHandle} />}
            />
        </FormControl>
    );
}
