import {AppBar, Button, FormControlLabel, IconButton, Switch, Typography} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import {makeStyles} from "@material-ui/core/styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExternalLink, faSync, faTimes} from "@fortawesome/pro-solid-svg-icons";
import React, {useEffect} from "react";
import DateRangePicker from "../../Forms/DateRangePicker";
import {useIntl} from "react-intl";
import {formatForId} from "../../../Utils/Lang/IntlHelper";
import {useStore} from "react-redux";
import {useForecastActive} from "../../../Utils/Data/hooks/meteogram";
import {setForecastActive} from "../../../Utils/Data/actions/meteogram";
import {useHasPermission} from "../../../Utils/Permissions/RequireAnyPermission";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    dateRange: {
        paddingRight: theme.spacing(5)
    },
    rangeButtons: {
        paddingRight: theme.spacing(3)
    },
    forecast: {
        paddingRight: theme.spacing(3)
    }
}));


function IntervalButton({interval, onIntervalSelected}) {
    const intl = useIntl();
    const classes = useStyles();

    return <Button color="inherit" onClick={() => onIntervalSelected(interval)}>
        {formatForId(intl, "pages.device.meteogram.control.interval." + interval)}
    </Button>
}

export function MeteogramControl({handleClose, handleReset, device, history, standalone}) {
    const classes = useStyles();
    const store = useStore();
    const intl = useIntl();

    const hasForecastPerm = useHasPermission({
        permission: "forecast__numeric", context: {
            domainID: device.domain_id,
            groupID: device.group_id
        }
    });

    useEffect(() => setForecastActive(store, hasForecastPerm), []);

    const forecastActive = useForecastActive();

    const openInNewWindow = () => {
        const url = window.location.origin + `/meteogram/?device_id=${device.id}&domain_id=${device.domain_id}&group_id=${device.group_id}`;
        window.open(url, "_blank");
    }


    return <AppBar className={classes.appBar}>
        <Toolbar>
            <Typography variant="h6" className={classes.title}>
                {device.name}
            </Typography>


            {hasForecastPerm &&
                <div className={classes.forecast}>
                    <FormControlLabel
                        control={<Switch checked={forecastActive}
                                         onChange={(event) => setForecastActive(store, event.target.checked)}/>
                        } label={formatForId(intl, "pages.device.meteogram.control.forecastLabel")}/>
                </div>
            }

            <div className={classes.dateRange}>
                <DateRangePicker value={history.value} disabled={false}
                                 onChange={(value) => history.setValue(value)} dense={true}/>
            </div>

            <IconButton color="inherit" onClick={handleReset}>
                <FontAwesomeIcon icon={faSync} size={"xs"}/>
            </IconButton>
            <IconButton color="inherit" onClick={openInNewWindow}>
                <FontAwesomeIcon icon={faExternalLink} size={"xs"}/>
            </IconButton>
            {!standalone && <IconButton color="inherit" onClick={handleClose}>
                <FontAwesomeIcon icon={faTimes} size={"xs"}/>
            </IconButton>}
        </Toolbar>
    </AppBar>
}