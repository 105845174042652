import { useMemo } from "react";
import { formatForId } from "../../Utils/Lang/IntlHelper";

const BaseNiraValue = {
    valueReducer: (val) => val,
    formatter: defaultFormatter,
};

const NiraValues = {
    temperature: {
        ...BaseNiraValue,
        formatter: (val, intl) => formatWithUnit(val, intl, "celsius"),
    },
    friction: {
        ...BaseNiraValue,
        formatter: frictionFormatter,
    },
    wiperspeed: {
        ...BaseNiraValue,
        valueReducer: (val) => val > 0,
        formatter: (val, intl) => formatForId(intl, `value.unit.wipesPerSecond.${val ? "1" : "0"}`),
    },
    air_pressure: {
        ...BaseNiraValue,
        formatter: (val, intl) => formatWithUnit(val, intl, "hpa"),
        valueReducer: (val) => val * 10,
    },
    relative_humidity: {
        ...BaseNiraValue,
        formatter: (val, intl) => formatWithUnit(val, intl, "percent"),
    },
    current_roughness: {
        ...BaseNiraValue,
    },
    long_term_roughness: {
        ...BaseNiraValue,
    },
};

function formatWithUnit(val, intl, unitKey) {
    if (val === undefined) {
        return "";
    }

    return `${defaultFormatter(val)} ${formatForId(intl, `value.unit.${unitKey}`)}`;
}

function defaultFormatter(val) {
    return val ? val.toFixed(1) : val;
}

function frictionFormatter(val) {
    return val ? val.toFixed(2) : val;
}

export function useNiraValueInfo(key) {
    return useMemo(() => (NiraValues.hasOwnProperty(key) ? NiraValues[key] : BaseNiraValue), [key]);
}
