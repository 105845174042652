import React, { useEffect, useMemo, useState } from "react";
import { useGet, useMutate } from "restful-react";
import { useActiveTextForecast, useAppAccess, useIsHistoryActive, useRadarImageChangeHandler, useViewsChangeHandler } from "./Utils/Data/hooks/server";
import _ from "loadsh";
import moment from "moment";
import { Last24HoursType, Last36HoursType, Last7DaysType, Last8HoursType, useDefaultDateRangePickerValue } from "./Components/Forms/DateRangeField/hooks";
import { useLocale } from "./Utils/Data/hooks/gui";
import { useIntervalWhen } from "rooks";

function postJson(url, data) {
    return handleApiCall(
        url,
        "post",
        fetch(url, {
            body: JSON.stringify(data),
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json",
            },
            method: "post",
        })
    );
}

export function usePostMutate(url) {
    const { mutate, cancel, loading, error, onMutate } = useMutate({ verb: "POST", path: url });
    const [response, setResponse] = useState(null);

    return {
        postData: async (data) => {
            const resp = await handleMutateApiCall(url, "POST", mutate(data));
            setResponse(resp);

            return resp;
        },
        cancel,
        loading,
        error,
        response,
        setResponse,
    };
}

export function useDeleteMutate(url) {
    const { mutate, cancel, loading, error, onMutate } = useMutate({ verb: "DELETE", path: url });

    return {
        deleteData: async () => {
            return await handleMutateApiCall(url, "DELETE", mutate(null));
        },
        cancel,
        loading,
        error,
    };
}

async function handleMutateApiCall(url, method, promise) {
    try {
        const response = await promise;

        return {
            status: 200,
            body: response,
            url: url,
            method: method,
        };
    } catch (e) {
        console.log(e);
        if (e.data) {
            return {
                ...e,
                body: e.data,
                url: url,
                method: method,
            };
        }
        return {
            ...e,
            body: {
                message: e.message,
            },
            url: url,
            method: method,
        };
    }
}

async function handleApiCall(url, method, promise) {
    try {
        const response = await promise;
        const status = response.status;
        let body;

        try {
            body = await response.json();
        } catch (e) {
            body = { message: "T:server.error" };
        }

        return {
            status: status,
            body: body,
            url: url,
            method: method,
        };
    } catch (e) {
        console.log(e);
        return {
            status: 666,
            body: {
                message: e.message,
            },

            url: url,
            method: method,
        };
    }
}

export function confirmAlert(ids) {
    return postJson("/api/alert/confirm/", ids);
}

export function useAlertHistory(shouldLoad, deviceFilter, levelFilter, history, groupByType, beginTime) {
    const queryParams = useMemo(() => {
        const baseFilter = {
            history_begin: beginTime ? beginTime : history.value.begin_time,
            history_end: history.value.end_time,
            filter_severity: levelFilter,
        };

        if (deviceFilter.length > 0) {
            const deviceFilterUrl = (() => {
                switch (deviceFilter.length) {
                    case 3:
                        return { device_ids: deviceFilter[2].join(",") };
                    case 2:
                        return { device_group_id: deviceFilter[1].id };
                    case 1:
                        return { domain_id: deviceFilter[0].id };
                }
            })();

            return { ...baseFilter, ...deviceFilterUrl };
        } else {
            return baseFilter;
        }
    }, [deviceFilter, levelFilter, history.value]);

    const props = useGet({ path: "/alert/list/", queryParams, lazy: true });

    useEffect(() => {
        if (shouldLoad) {
            props.refetch();
        }
    }, [shouldLoad, queryParams]);

    const data = useMemo(() => {
        if (props.data) {
            const groupByTime = (data) => {
                const byTime = _.groupBy(
                    data,
                    (row) => (row.level === 100 ? 1 : 0) + ":" + row.device.id + ":" + row.begin_time + ":" + row.end_time + ":" + (groupByType ? row.level + ":" : "") + row.confirmation?.user?.id
                );
                const mapMetadata = (atSameTime) => {
                    const metadata = _.map(
                        _.filter(atSameTime, (alert) => !!alert.metadata),
                        (alert) => alert.metadata
                    );
                    if (_.isEmpty(metadata)) return null;

                    return _.map(
                        _.groupBy(metadata, (alertMetadata) => JSON.stringify(alertMetadata)),
                        (sameMetadata) => _.first(sameMetadata)
                    );
                };

                const groupedByTime = _.map(byTime, (atSameTime, key) => ({
                    id: key,
                    begin_time: atSameTime[0].begin_time,
                    end_time: atSameTime[0].end_time,
                    confirmation: atSameTime[0].confirmation,
                    confirmable: atSameTime[0].confirmable,
                    device: atSameTime[0].device,
                    level: _.maxBy(atSameTime, (row) => row.level).level,
                    ids: _.map(atSameTime, (row) => row.id),
                    metadata: mapMetadata(atSameTime),
                    alerts: atSameTime,
                }));

                for (const row of groupedByTime) {
                    const types = [];
                    for (const alert of row.alerts) {
                        if (alert.level === row.level) {
                            types.push(alert.type);
                        }
                    }

                    row.types = types;
                }

                return groupedByTime;
            };

            const sortedByLevelFirstFilter = (collection, predicate) =>
                _.orderBy(_.filter(collection, predicate), [(row) => (row.confirmation !== null ? 1 : 0), "level", "begin_time"], ["asc", "desc", "desc"]);
            const sortedFilter = (collection, predicate) => _.orderBy(_.filter(collection, predicate), ["begin_time"], ["desc"]);

            return {
                active: sortedByLevelFirstFilter(groupByTime(props.data.active)),
                notActive: sortedFilter(groupByTime(props.data.history)),
            };
        } else {
            return {
                active: [],
                notActive: [],
            };
        }
    }, [props.data]);

    return {
        ...props,
        data,
    };
}

export function useCurrentTextForecast(load) {
    const { lastReadTextForecastId } = useActiveTextForecast();
    const props = useGet({ path: "/forecast/text/latest/", lazy: true });

    useEffect(() => {
        if (load) {
            props.refetch();
        }
    }, [load]);

    useEffect(() => {
        if (props.data?.id && props.data.id !== lastReadTextForecastId) {
            postJson("/api/forecast/text/read/", { text_forecast_id: props.data?.id });
        }
    }, [props.data?.id, lastReadTextForecastId]);

    return props;
}

function calcRefreshValue(value) {
    let result = { ...value };
    switch (value.type) {
        case Last24HoursType:
            result = { type: value.type, begin: moment().add(-24, "hours").startOf("hours"), end: null };
            break;
        case Last36HoursType:
            result = { type: value.type, begin: moment().add(-36, "hours").startOf("hours"), end: null };
            break;

        case Last7DaysType:
            result = { type: value.type, begin: moment().add(-7, "days").startOf("days"), end: null };
            break;
    }
    result.begin_time = result.begin.toDate().getTime();
    result.end_time = result.end ? result.end.toDate().getTime() : 0;

    return result;
}

export function useHistoryFilter(type, end) {
    const defaultValue = useDefaultDateRangePickerValue(_.isEmpty(type) ? Last8HoursType : type, end);

    const [value, setNewValue] = useState(() => ({
        ...defaultValue,
        begin_time: defaultValue.begin.toDate().getTime(),
        end_time: defaultValue.end ? defaultValue.end.toDate().getTime() : 0,
    }));

    return useMemo(
        () => ({
            value,
            setValue(value) {
                setNewValue({
                    ...value,
                    baseTime: defaultValue.baseTime,
                    begin_time: value.begin.toDate().getTime(),
                    end_time: value.end ? value.end.toDate().getTime() : end ? end.toDate().getTime() : 0,
                });
            },
            refresh() {
                setNewValue((val) => calcRefreshValue(val));
            },
        }),
        [value]
    );
}

export function useDeviceFieldHistory(history, shouldLoad, deviceId, columns, beginTime) {
    const historyActive = useIsHistoryActive();

    const props = useGet({
        path: "/history/list/",
        queryParams: {
            begin_time: beginTime,
            end_time: history.value.end_time,
            device_id: deviceId,
            columns: columns,
            data_store: "meteo_v1",
        },
        lazy: true,
    });

    useEffect(() => {
        if (shouldLoad) {
            props.refetch();
        }
    }, [shouldLoad, history.value, columns, beginTime]);

    useIntervalWhen(() => props.refetch(), 1000 * 60, !historyActive, false);

    return { data: props };
}

export function useNumericForecast(history, shouldLoad, deviceCustomId, columns, beginTime) {
    const props = useGet({
        path: "/forecast/numeric/list/",
        queryParams: {
            begin_time: beginTime,
            end_time: history.value.end_time,
            location_id: deviceCustomId,
            columns: columns,
        },
        lazy: true,
    });

    useEffect(() => {
        if (shouldLoad) {
            props.refetch();
        } else {
            props.data = [];
        }
    }, [shouldLoad, history, columns]);

    const issueTime = React.useMemo(() => {
        if (props.loading || _.isEmpty(props.data)) {
            return null;
        } else {
            return {
                min: _.minBy(props.data, (val) => val.issued_time),
                max: _.maxBy(props.data, (val) => val.issued_time),
            };
        }
    }, [props.loading, props.data]);

    useIntervalWhen(() => props.refetch(), 1000 * 60, true, false);

    return { forecastData: props, issueTime };
}

export function useDrivers(driverInterface, customFilter = null) {
    const {
        data: configurations,
        refetch: reload,
        loading,
    } = useGet({
        base: "/public-api/",
        path: "device-driver/list/",
    });

    const drivers = useMemo(() => _.filter(configurations, customFilter || ((item) => item.metadata.interface === driverInterface)) || [], [configurations]);
    const names = useMemo(() => _.map(drivers, ({ metadata }) => metadata.name), [drivers]);
    return {
        drivers,
        names,
        loading,
        getFormFields(driverName) {
            const driver = _.find(configurations, (driver) => driver.metadata.name === driverName);
            const fixFields = (fields) => {
                return fields.map((field) => {
                    const newField = _.clone(field);
                    const newProps = {};

                    _.forEach(newField.props, (value, key) => {
                        if (value.type === "bool") {
                            newProps[key] = !!value.value;
                        } else {
                            newProps[key] = value.value;
                        }
                    });

                    newField.props = newProps;
                    if (newProps.hasOwnProperty("defaultValue")) {
                        newField.defaultValue = newProps.defaultValue;
                    }

                    return newField;
                });
            };

            if (driver) {
                const { metadata } = driver;
                if (!driverInterface) {
                    return fixFields(metadata?.template_configuration_form?.fields || []);
                } else if (driverInterface.indexOf("master/") === 0) {
                    return fixFields(metadata?.master_configuration_form?.fields || []);
                } else {
                    return fixFields(metadata?.configuration_form?.fields || []);
                }
            }
            return [];
        },
    };
}

export function useDataSources(domainId) {
    const { data, loading, refetch } = useGet("domain/" + domainId + "/device-group/list/");

    const dataSources = useMemo(
        () =>
            _.map(_.find(data, (group) => group.id < 0)?.devices, (dev) => ({
                ...dev,
                name: dev.name.substring(0, dev.name.length - 3),
            })) || [],
        [data]
    );
    return { dataSources, loading, refetch };
}

export function useDriverTemplates(domainId, driver) {
    const { data, loading, refetch } = useGet("domain/" + domainId + "/configuration-template/list/");

    const templates = useMemo(() => _.filter(data, (tmpl) => tmpl.driver === driver), [data, driver]);
    return { templates, loading, refetch };
}

export function useDomainGroupDevices({ domainId, groupId }) {
    const { data, loading, refetch } = useGet("domain/" + domainId + "/device-group/list/", { lazy: true });
    useEffect(() => {
        refetch();
    }, [domainId]);

    const devices = useMemo(() => _.find(data, (group) => group.id === groupId)?.devices || [], [data, groupId]);

    return { devices, loading, refetch };
}

export function useViewsList(includeDeviceCount = false) {
    const appAccess = useAppAccess();
    const viewChangeHandler = useViewsChangeHandler();
    const [locale] = useLocale();

    const { data, loading, refetch, error } = useGet("view/access/list/", {
        lazy: true,
        queryParams: {
            include_device_count: includeDeviceCount ? 1 : 0,
        },
    });

    useEffect(() => {
        if (viewChangeHandler === 0) {
            refetch();
        } else {
            const timeout = setTimeout(() => {
                refetch();
            }, Math.random() * 10000);

            return () => {
                clearTimeout(timeout);
            };
        }
    }, [appAccess, viewChangeHandler]);

    const views = useMemo(() => {
        if (data) {
            return data.sort((a, b) => a.name.localeCompare(b.name, locale));
        } else {
            return [];
        }
    }, [data]);

    return { views, loading, refetch, error };
}

export function useRadarForecast({ locationId = "sk", type = 1, shouldLoad, startTime, endTime }) {
    const radarImageChange = useRadarImageChangeHandler();

    const { data, loading, refetch } = useGet({
        path: "/radar/playlist/",
        queryParams: {
            location_id: locationId,
            type: type,
            start_time: startTime,
            end_time: endTime,
        },
        lazy: true,
    });

    useEffect(() => {
        if (shouldLoad) {
            refetch();
        }
    }, [shouldLoad, locationId, type, radarImageChange, startTime]);

    return { data: data, loading, refetch };
}
