// eslint-disable-next-line import/no-webpack-loader-syntax
import langSk from "!!raw-loader!./Lang.sk.yaml";
// eslint-disable-next-line import/no-webpack-loader-syntax
import langEn from "!!raw-loader!./Lang.en.yaml";

import _ from "underscore";
import yaml from "js-yaml";

export function getDefaultLocale() {
    let default_locale = "en";
    if (navigator.languages !== undefined) {
        default_locale = navigator.languages[0];
    }
    default_locale = navigator.language;

    if (default_locale.indexOf("-") !== -1) default_locale = default_locale.split("-")[0];

    if (default_locale.indexOf("_") !== -1) default_locale = default_locale.split("_")[0];

    return default_locale;
}

export function getIntlCatalog(langKey) {
    const result = {};

    let catalog;

    switch (langKey) {
        case "sk":
            catalog = yaml.load(langSk);
            break;
        case "en":
            catalog = yaml.load(langEn);
            break;
        default:
            catalog = yaml.load(langEn);
    }

    function traverse(key, level) {
        if (typeof level === "object") {
            for (const innerKey of _.keys(level)) {
                traverse(key + "." + innerKey, level[innerKey]);
            }
        } else {
            result[key] = level;
        }
    }

    for (const key of _.keys(catalog)) {
        traverse(key, catalog[key]);
    }

    return result;
}
