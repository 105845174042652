import React, { useEffect, useMemo, useState } from "react";

import { useCanvasJsResizableChartRef, useCrosshair, useCrossHairViewOptions } from "./hooks";

import CanvasJSReact from "../../../Charts/canvasjs.stock.react";

import _ from "loadsh";

import { useIntl } from "react-intl";
import { Paper, Typography, useTheme } from "@material-ui/core";

import { useValueInfos } from "../../../../Utils/Data/ValueMapper";
import { formatForId } from "../../../../Utils/Lang/IntlHelper";
import { createChartOptions } from "./data/MeteoDataChart";
import "./style.css";
import { useToggle } from "rooks";

const CanvasJSStockChart = CanvasJSReact.CanvasJSStockChart;

export function MainChart({ data, columns, history, alertData, forecastData, forecastActive, height, alertsViewSettings, beginTime, hiddenColumns }) {
    const intl = useIntl();
    const chartRef = useCanvasJsResizableChartRef();
    const [valueInfos, findValueInfo] = useValueInfos(true);
    const theme = useTheme();
    const [dataVersion, setDataVersion] = useState(Date.now());
    const [initialViewport, setInitialViewport] = useState(null);
    const [reset, setReset] = useToggle();

    useEffect(() => {
        setDataVersion((st) => Date.now());
    }, [history, data]);

    const [onCrosshairChanged, onCrosshairClicked, onIgnoreNextClick] = useCrosshair(chartRef, dataVersion, data, forecastData, columns, alertData, intl, theme);

    const onViewportChanged = (e) => {
        if (e.trigger === "reset") {
            setInitialViewport(null);
            setReset();
        } else {
            setInitialViewport(e.axisX[0]);

            if (chartRef.current) {
                const stockChart = chartRef.current.stockChart;
                if (stockChart) {
                    stockChart._axisXMin = e.axisX[0].viewportMinimum;
                    stockChart._axisXMax = e.axisX[0].viewportMaximum;
                }
                chartRef.current.stockChart.render();
            }
        }
    };

    useEffect(() => setInitialViewport(null), [history]);

    const options = useMemo(() => {
        return createChartOptions({
            history,
            findValueInfo,
            data,
            forecastData,
            forecastActive,
            intl,
            theme,
            alertData,
            columns,
            alertsViewSettings,
            beginTime,
            hiddenColumns,
            onViewportChanged,
        });
    }, [history, data, alertData, columns, forecastData, forecastActive, alertsViewSettings, beginTime, setInitialViewport, reset]);

    const optionsWithHeight = useMemo(() => {
        const oldHeight = options.height;
        options.height = height;
        if (options.charts.length > 1) {
            if (options.charts.length === 2) {
                options.charts[0].height = 0.14 * options.height;
                options.charts[1].height = 0.86 * options.height;
            } else if (options.charts.length === 3) {
                options.charts[0].height = 0.14 * options.height;
                options.charts[1].height = 0.23 * options.height;
                options.charts[2].height = 0.63 * options.height;
            } else if (options.charts.length === 4) {
                options.charts[0].height = 0.14 * options.height;
                options.charts[1].height = 0.12 * options.height;
                options.charts[2].height = 0.12 * options.height;
                options.charts[3].height = 0.62 * options.height;
            }
        }

        return options;
    }, [options, height]);

    const optionsWithCrossHair = useCrossHairViewOptions(
        theme,
        optionsWithHeight,
        dataVersion,
        formatForId(intl, "pages.device.meteogram.now"),
        onCrosshairChanged,
        onCrosshairClicked,
        onIgnoreNextClick
    );

    const emptyData = _.isEmpty(data) && ((_.isEmpty(forecastData) && forecastActive) || !forecastActive);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (chartRef.current) {
                const stockChart = chartRef.current.stockChart;
                if (stockChart) {
                    if (initialViewport !== null && initialViewport.viewportMinimum !== null && initialViewport.viewportMaximum !== null) {
                        stockChart._axisXMin = initialViewport.viewportMinimum;
                        stockChart._axisXMax = initialViewport.viewportMaximum;
                    } else {
                        stockChart._axisXMin = chartRef.current.options.charts[0].axisX.viewportMinimum;
                        stockChart._axisXMax = chartRef.current.options.charts[0].axisX.viewportMaximum;
                    }
                }

                chartRef.current.stockChart.render();
            }
        }, 0);

        return () => clearTimeout(timeout);
    }, [options, chartRef.current]);

    return (
        <div className={"main-chart"} style={{ position: "relative" }} onClick={onCrosshairClicked}>
            <CanvasJSStockChart key={dataVersion + emptyData} options={optionsWithCrossHair} ref={chartRef} />

            {emptyData && (
                <>
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            width: "100%",
                            height: "100%",
                        }}
                    ></div>
                    <div style={{ position: "absolute", top: 50, width: "100%" }}>
                        <Paper
                            style={{
                                padding: 22,
                                width: 500,
                                marginLeft: "auto",
                                marginRight: "auto",
                                backgroundColor: "#343B46",
                                textAlign: "center",
                            }}
                        >
                            <Typography variant={"h5"}>{formatForId(intl, "pages.device.meteogram.noData")}</Typography>
                        </Paper>
                    </div>
                </>
            )}
        </div>
    );
}
