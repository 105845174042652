import { useSelector } from "react-redux";
import _ from "loadsh";
import { useMemo } from "react";
import { DEFAULT_APP_FIELDS_ORDER, DEFAULT_DATA_FIELDS_ORDER, SPECIAL_BUTTONS_APP_FIELD, SPECIAL_BUTTONS_LIST } from "../ViewsDefinitions";
import { None } from "../../../Components/NiraApi";

const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];

export function useDeviceDataActiveView() {
    return useSelector(({ deviceDataView }) => {
        return deviceDataView.activeView;
    });
}

export function useDeviceDataActiveViewLoadingState() {
    return useSelector(({ deviceDataView }) => {
        return deviceDataView.activeViewLoadingState;
    });
}

export function useDeviceDataActiveViewDevices() {
    const devices = useSelector(({ deviceDataView }) => {
        return deviceDataView?.activeView?.devices || EMPTY_ARRAY;
    });

    const searchKey = useActiveDeviceDataViewSearchKey();

    const customNormalize = (str) =>
        str
            .normalize("NFD")
            .replace(/\p{Diacritic}/gu, "")
            .toLowerCase();

    return useMemo(
        () =>
            _.isEmpty(searchKey)
                ? devices
                : devices.filter((device) => customNormalize(device.name).includes(customNormalize(searchKey)) || customNormalize(device.custom_id).includes(customNormalize(searchKey))),
        [searchKey, devices]
    );
}

export function useViewDefaultNiraField() {
    const niraField = useSelector(({ deviceDataView }) => {
        return deviceDataView?.activeView?.config?.nira_field || None;
    });
    return niraField;
}

export function useViewConfig() {
    return useSelector(({ deviceDataView }) => {
        return deviceDataView?.activeView?.config || EMPTY_OBJECT;
    });
}

export function useViewMapConfig() {
    const viewConfig = useViewConfig();

    return useMemo(() => {
        const viewCustomFields = JSON.parse(viewConfig.gui_custom_field ? viewConfig.gui_custom_field : "{}");
        if (viewCustomFields.map_zoom || viewCustomFields.map_lat || viewCustomFields.map_lon) {
            return viewCustomFields;
        } else return {};
    }, [viewConfig]);
}

export function useVisibleDomainAndGroups() {
    const deviceDataView = useSelector(({ deviceDataView }) => deviceDataView);

    const domains = useMemo(() => {
        const domains = new Map();
        if (deviceDataView.activeView) {
            const { context } = deviceDataView.activeView;
            if (context.type === "domain-group") {
                const { domainId, groupId } = context;
                const groups = new Set();
                groups.add(groupId);
                domains.set(domainId, groups);
            } else if (context.type === "view") {
                for (const dev of deviceDataView.activeView.devices) {
                    let groups = domains.get(dev.domain_id);
                    if (!groups) {
                        groups = new Set();
                        domains.set(dev.domain_id, groups);
                    }

                    groups.add(dev.group_id);
                }
            }
        }
        return domains;
    }, [deviceDataView, deviceDataView?.activeView?.devices]);

    return domains;
}

export function useAppFieldsOrder() {
    const viewConfig = useViewConfig();

    return useMemo(() => {
        if (_.isEmpty(viewConfig?.visible_app_fields)) {
            return DEFAULT_APP_FIELDS_ORDER;
        } else {
            return viewConfig.visible_app_fields;
        }
    }, [viewConfig]);
}

export function useSpecialButtonsList() {
    const viewConfig = useViewConfig();

    return useMemo(() => {
        if (viewConfig?.visible_app_fields && viewConfig.visible_app_fields.indexOf(SPECIAL_BUTTONS_APP_FIELD) !== -1) {
            return viewConfig.special_buttons;
        } else {
            return SPECIAL_BUTTONS_LIST;
        }
    }, [viewConfig]);
}

export function useDataFieldsOrder() {
    const viewConfig = useViewConfig();
    return useMemo(() => {
        if (_.isEmpty(viewConfig?.visible_data_fields)) {
            return DEFAULT_DATA_FIELDS_ORDER;
        } else {
            return viewConfig.visible_data_fields;
        }
    }, [viewConfig]);
}

export function useVisibleDataFields(valueInfo, driverTypes) {
    return useMemo(() => {
        if (!valueInfo.driverTypes) {
            return true;
        }
        return driverTypes.filter((type) => valueInfo.driverTypes.includes(type)).length > 0;
    }, [driverTypes]);
}

export function useActiveDeviceDataViewSearchKey() {
    return useSelector(({ deviceDataView }) => {
        return deviceDataView.searchKey;
    });
}
