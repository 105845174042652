import React from "react"
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {formatForId} from "../../Utils/Lang/IntlHelper";
import {useIntl} from "react-intl";
import {useCurrentTextForecast} from "../../Api";
import {formatTime} from "../../Utils/Data/Time";
import {makeStyles} from "@material-ui/styles";
import { CancelButton } from "../CommonDialog";

const useStyles = makeStyles(()=>({
   issuedTime:{
      fontSize: "smaller",
      color: "gray",
      marginLeft: "0.5rem"
   }
}))
export function TextForecastDialog({open, onClose}) {
   const intl = useIntl();
   const classes = useStyles();
   const {data, loading, refetch, response} = useCurrentTextForecast(open);

   const hasData = data && !loading && open;
   return (<Dialog open={open} onClose={onClose} maxWidth={"xl"} fullWidth={true} scroll={"paper"}>
                  <CancelButton onClose={onClose}/>
      <DialogTitle>
         { formatForId(intl, "TextForecastDialog.title")}
         {hasData && <span className={classes.issuedTime}>{formatTime(data.issued)}</span> }
      </DialogTitle>
      <DialogContent>
         <pre>
            {!loading && response?.status!==200 &&  formatForId(intl, "TextForecastDialog.noTextForecastImported")}
            {hasData && data.text}
            {!hasData && loading && "Loading..."}
         </pre>
      </DialogContent>
      <DialogActions>
        
      </DialogActions>
   </Dialog>);
}