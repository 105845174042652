import moment from "moment";
import { getDefaultLocale } from "../Lang/LangLoader";

function localizedMoment(value) {
    const locale = getDefaultLocale();
    const m = moment(value);
    m.locale(locale);
    return m;
}

export function formatTime(value, seconds = false) {
    if (value) {
        const mnt = localizedMoment(value);
        return mnt.format("L") + " " + (seconds ? mnt.format("LTS") : mnt.format("LT"));
    } else {
        return "";
    }
}
export function formatDayTimeShort(value) {
    if (value) {
        const fmt = localizedMoment(value);
        return formatDayMonthOnly(value) + " " + fmt.format("LT");
    } else {
        return "";
    }
}

export function formatTimeOnly(value, seconds = false) {
    if (value) {
        const mnt = localizedMoment(value);
        return seconds ? mnt.format("LTS") : mnt.format("LT");
    } else {
        return "";
    }
}

export function formatHoursOnly(value) {
    if (value) {
        const mnt = localizedMoment(value);
        return mnt.format("HH");
    } else {
        return "";
    }
}

export function formatDayOfTheWeek(value) {
    if (value) {
        const mnt = localizedMoment(value);
        if (mnt.hours() === 0 && mnt.minute() === 0) {
            const result = mnt.format("ddd D");
            return result.charAt(0).toUpperCase() + result.substring(1);
        } else {
            return "";
        }
    } else {
        return "";
    }
}

export function formatDayMonthOnly(value) {
    if (value) {
        const fmt = localizedMoment(value);
        return fmt.format("L").replace(new RegExp("[^.]?" + moment().format("YYYY") + ".?"), "");
    } else return "";
}
