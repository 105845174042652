import {FormControl} from "@material-ui/core";
import {buildFormEntityValues, findFirstFocusableFieldId, RenderField, useFormCommon} from "./FormCommon";
import React, {useRef} from "react";
import {useIntl} from "react-intl";
import {connect} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import {addEventListener, removeAllListenersForEvent, removeEventListener} from "react-redux-events";


const useStyles = makeStyles((theme) => ({
   formGroup:  {
      display: "flex",
      flexDirection: "row"
   }
}));


const mapProps = state => ({});
const mapDispatch = dispatch => ({
   addEventListener: (event, context, handler, priority) => dispatch(addEventListener(event, context, handler, priority)),
   removeEventListener: (event) => dispatch(removeEventListener(event)),
   removeAllListenersForEvent: (event) => dispatch(removeAllListenersForEvent(event)),

});

export default connect(mapProps, mapDispatch)(function DialogForm({
                                                                     formData, formId, formConfig, onSubmit,
                                                                     submitEventId,
                                                                     addEventListener,
                                                                     removeEventListener,
                                                                     removeAllListenersForEvent
                                                                  }) {
   const intl = useIntl();
   const classes = useStyles();

   const ref = useRef();
   const focusFieldId = React.useMemo(() => findFirstFocusableFieldId(formConfig), [formConfig]);
   const initialValues = React.useMemo(() => buildFormEntityValues(formConfig, formData), [formConfig, formData]);

   const formik = useFormCommon(formConfig, initialValues, onSubmit);

   React.useEffect(()=>{
      addEventListener(submitEventId, this, ()=>formik.submitForm());
      return ()=>removeAllListenersForEvent(submitEventId);
   },[formik, submitEventId])

   return <form autoComplete={"off"}> <FormControl fullWidth classes={{root: classes.mainForm}}>


      {
         formConfig.map((item, idx) => RenderField(
            {intl, changeHandle: (evt) => {
            formik.handleChange(evt);
         }, item, formik, formId, focusFieldId, ref, editable:true, classes}))

      }
   </FormControl></form>


});
