import {AppBar, Button, FormControlLabel, Grid, IconButton, Switch, Typography} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import {makeStyles} from "@material-ui/core/styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle, faExternalLink, faSync, faTimes} from "@fortawesome/pro-solid-svg-icons";
import React, {useEffect} from "react";
import DateRangePicker from "../../Forms/DateRangePicker";
import moment from "moment";
import {useIntl} from "react-intl";
import {formatForId} from "../../../Utils/Lang/IntlHelper";
import {useStore} from "react-redux";
import {useForecastActive} from "../../../Utils/Data/hooks/meteogram";
import {setForecastActive} from "../../../Utils/Data/actions/meteogram";
import {useHistoryGetData} from "../../../Utils/Data/hooks/server";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import {formatDayTimeShort} from "../../../Utils/Data/Time";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline"
    },
    dateRange: {
        paddingRight: theme.spacing(5)
    },
    rangeButtons: {
        paddingRight: theme.spacing(3)
    },
    forecast: {
        paddingRight: theme.spacing(3)
    }
}));


export function MeteogramControlCustom({
                                           handleClose,
                                           onRefresh,
                                           device,
                                           future,
                                           onFutureChanged,
                                           standalone,
                                           issueTime
                                       }) {
    const classes = useStyles();
    const store = useStore();

    const openInNewWindow = () => {
        const url = window.location.origin + `/meteogram/?device_id=${device.id}&domain_id=${device.domain_id}&group_id=${device.group_id}`;
        window.open(url, "_blank");
    }
    const intl = useIntl();


    useEffect(() => setForecastActive(store, true), []);

    return <AppBar className={classes.appBar}>
        <Toolbar>
            <div className={classes.title}>
                <div>
                    <Typography variant="h6">

                        {device.name}
                    </Typography>
                </div>
                <div style={{color: "lightgray"}}>
                    <Typography variant="h6">
                        &nbsp; | &nbsp;
                    </Typography>
                </div>
                <div style={{color: "lightgray"}}>
                    <Typography variant="subtitle1">
                        {formatForId(intl, "pages.device.meteogram.title")}
                        {issueTime && issueTime.min.issued_time === issueTime.max.issued_time && formatDayTimeShort(issueTime.min.issued_time)}
                        {issueTime && issueTime.min.issued_time !== issueTime.max.issued_time &&
                            <>{formatDayTimeShort(issueTime.min.issued_time)} - {formatDayTimeShort(issueTime.max.issued_time)}</>
                        }
                    </Typography>
                </div>
            </div>
            <ToggleButtonGroup className={classes.rangeButtons} value={future.hours.toString()} exclusive
                               onChange={(event, value) => {
                                   onFutureChanged({hours: parseInt(value, 10)});
                               }
                               }>
                <ToggleButton value="72">
                    {formatForId(intl, "pages.device.meteogram.control.interval.72")}
                </ToggleButton>
                <ToggleButton value="48">
                    {formatForId(intl, "pages.device.meteogram.control.interval.48")}
                </ToggleButton>
                <ToggleButton value="24">
                    {formatForId(intl, "pages.device.meteogram.control.interval.24")}
                </ToggleButton>
            </ToggleButtonGroup>

            <IconButton color="inherit" onClick={onRefresh}>
                <FontAwesomeIcon icon={faSync} size={"xs"}/>
            </IconButton>

            {!standalone && <IconButton color="inherit" onClick={handleClose}>
                <FontAwesomeIcon icon={faTimes} size={"xs"}/>
            </IconButton>}
        </Toolbar>
    </AppBar>
}