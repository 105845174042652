export function setCrosshairData(store, timePosition,
                                 selectedDataRow,
                                 selectedForecastRow, stickSelection, alertData) {
    store.dispatch({
        type: "METEO::METEOGRAM",
        payload: {crosshairData: {timePosition, selectedDataRow, selectedForecastRow, stickSelection, alertData}}
    })
}

export function setForecastActive(store, active) {
    store.dispatch({
        type: "METEO::METEOGRAM",
        payload: {forecastActive: active}
    })
}

export function resetStickSelection(store) {
    store.dispatch({
        type: "METEO::METEOGRAM",
        payload: {stickSelectionReset: Date.now()}
    })
}