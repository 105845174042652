import { useInsufficientNiraZoom, useSelectedDataType, useSelectedRoughnessDataTime } from "../../Utils/Data/hooks/map";
import React, { useMemo } from "react";
import { alpha, makeStyles } from "@material-ui/core/styles";
import { FormControl, IconButton, MenuItem, Select, Typography } from "@material-ui/core";
import { setNiraDataPanelEnabled, setSelectedDataType, setSelectedRoughnessDataTime } from "../../Utils/Data/actions/map";
import { useStore } from "react-redux";
import { useIntl } from "react-intl";
import { formatForId } from "../../Utils/Lang/IntlHelper";
import { None, RoughnessChangesAlerts, SelectableNiraDataTypes, useRoughnessAlertsInterval } from "../NiraApi";
import { useAppAccess } from "../../Utils/Data/hooks/server";
import { calcAnyAccess } from "../../Utils/Permissions/RequireAnyPermission";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { formatNiraDataTs } from "./Nira/VectorGrid/SegmentPopup";
import { GradientPanel } from "./Nira/Gradient";
import { NiraArrowDownIcon, NiraArrowUpIcon } from "../Icons/NiraIcons";
import { globalRequestLimiterEventName } from "./Nira/VectorGrid/RequestLimiter";
import { faLoader } from "@fortawesome/pro-light-svg-icons";

import "./loadingRotation.css";
import { formatDayMonthOnly } from "../../Utils/Data/Time";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(0.5),
        alignItems: "center",
        backgroundColor: alpha(theme.palette.background.appBar, 0.8),
        border: "1px solid rgba(255, 255, 255, 0.23)",
        borderRadius: 8,
        pointerEvents: "auto",
        width: 367,
    },
    rootControl: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
    },
    dataSelect: {
        minWidth: 170,
    },
    timeSelect: {
        width: 200,
        alignSelf: "start",
        marginTop: -2,
    },
    dataTimestamp: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingLeft: theme.spacing(1),
    },
    legend: {
        minWidth: 350,
        paddingTop: theme.spacing(0.5),
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
    },
    roughnessControl: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    roughnessIconWrapper: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginTop: -2,
    },
    roughnessIcon: {
        width: 20,
        height: 20,
    },
    insufficientZoom: {
        backgroundColor: alpha(theme.palette.background.appBar, 0.8),
        border: "1px solid rgba(255, 255, 255, 0.23)",
        borderRadius: 8,
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));

function DataSelect({ menuItems }) {
    const classes = useStyles();
    const store = useStore();
    const dataType = useSelectedDataType();
    const intl = useIntl();

    return (
        <div className={classes.dataSelect}>
            <FormControl fullWidth>
                <Select
                    fullWidth
                    value={dataType}
                    onChange={(evt) => {
                        setSelectedDataType(store, evt.target.value);
                    }}
                >
                    {menuItems.map((type) => (
                        <MenuItem value={type}>{formatForId(intl, "pages.views.mapView.nira.dataType." + type)}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}

const RoughnessItems = [2, 5, 7, 14, 21, 30, 60];

function RoughnessAlertSelect({}) {
    const classes = useStyles();
    const store = useStore();
    const initialTime = useSelectedRoughnessDataTime();
    const intl = useIntl();

    const RoughnessIcon = ({ intlKey, icon }) => {
        return (
            <div className={classes.roughnessIconWrapper}>
                <div style={{ background: `url(${icon})` }} className={classes.roughnessIcon}></div>
                <Typography variant={"body1"} style={{ paddingLeft: 6 }}>
                    {formatForId(intl, intlKey)}
                </Typography>
            </div>
        );
    };

    return (
        <div className={classes.roughnessControl}>
            <div className={classes.timeSelect}>
                <FormControl fullWidth>
                    <Select
                        fullWidth
                        value={initialTime}
                        onChange={(evt) => {
                            setSelectedRoughnessDataTime(store, evt.target.value);
                        }}
                    >
                        {RoughnessItems.map((item) => (
                            <MenuItem value={item}>{formatForId(intl, "pages.views.mapView.nira.timeSelect.last" + item + "Days")}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <RoughnessIcon icon={NiraArrowUpIcon} intlKey={"pages.views.mapView.nira.dataPanel.roughnessChanges.up"} />
            <RoughnessIcon icon={NiraArrowDownIcon} intlKey={"pages.views.mapView.nira.dataPanel.roughnessChanges.down"} />
        </div>
    );
}

function DataTimestamp({ dataTs, dataType, classes }) {
    return (
        <div className={classes.dataTimestamp}>
            <Typography variant={"body1"}>{formatNiraDataTs(dataType, dataTs, true)}</Typography>
        </div>
    );
}

function RoughnessAlertsDataTimestamp({ classes, niraData }) {
    const { startDate, endDate } = useRoughnessAlertsInterval(niraData);
    return (
        <div className={classes.dataTimestamp}>
            <Typography variant={"body1"}>{formatDayMonthOnly(startDate) + " - " + formatDayMonthOnly(endDate)}</Typography>
        </div>
    );
}

export function InsufficientZoom({ dataType }) {
    const classes = useStyles();
    const intl = useIntl();
    const insufficientZoom = useInsufficientNiraZoom();
    const niraDataType = useSelectedDataType();
    if (insufficientZoom && niraDataType !== None) {
        return (
            <div className={classes.insufficientZoom}>
                <FontAwesomeIcon icon={faCircleInfo} size={"lg"} style={{ paddingRight: 8 }} />
                <Typography>{formatForId(intl, "pages.views.mapView.nira.dataPanel.insufficientZoom")}</Typography>
            </div>
        );
    } else {
        return <></>;
    }
}

export const NiraPerms = ["nira__friction", "nira__temperature", "nira__wiperspeed", "nira__air_pressure", "nira__relative_humidity", "nira__current_roughness", "nira__long_term_roughness"];

function useNiraLoading() {
    const [loading, setLoading] = React.useState(false);
    React.useEffect(() => {
        const handler = (evt) => {
            setLoading(evt.data.isDownloading);
        };
        document.addEventListener(globalRequestLimiterEventName, handler);
        return () => {
            document.removeEventListener(globalRequestLimiterEventName, handler);
        };
    }, []);
    return loading;
}

export function NiraDataPanel({ niraData }) {
    const classes = useStyles();
    const store = useStore();
    const intl = useIntl();
    const { dataType, dataTs } = niraData;
    const appAccess = useAppAccess();
    const hasRoughnessAlerts = useMemo(() => dataType === RoughnessChangesAlerts, [dataType]);

    const menuItems = useMemo(() => SelectableNiraDataTypes.filter((type) => calcAnyAccess(appAccess, null, "nira__" + type)), [appAccess]);

    const isNiraLoading = useNiraLoading();

    return (
        <div className={classes.root}>
            <div className={classes.rootControl}>
                <DataSelect menuItems={menuItems} />
                {!hasRoughnessAlerts && <DataTimestamp dataTs={dataTs} dataType={dataType} classes={classes} />}
                {hasRoughnessAlerts && <RoughnessAlertsDataTimestamp niraData={niraData} classes={classes} />}

                <IconButton
                    style={{ width: "2rem" }}
                    onClick={() => {
                        setNiraDataPanelEnabled(store, false);
                        setSelectedDataType(store, None);
                    }}
                    title={isNiraLoading ? formatForId(intl, "pages.views.mapView.nira.dataPanel.cancelLoading") : formatForId(intl, "pages.views.mapView.nira.dataPanel.close")}
                >
                    <FontAwesomeIcon icon={isNiraLoading ? faLoader : faTimes} className={isNiraLoading ? "loadingRotation" : ""} />
                </IconButton>
            </div>

            {hasRoughnessAlerts && <RoughnessAlertSelect />}

            {!hasRoughnessAlerts && (
                <div className={classes.legend}>
                    <GradientPanel dataType={dataType} />
                </div>
            )}
        </div>
    );
}
