import React, {useMemo, useState} from "react";
import MasterDetailView from "../../../Components/MasterDetail/MasterDetailView";
import Button from "@material-ui/core/Button";
import {formatForId, translateColumns} from "../../../Utils/Lang/IntlHelper";
import {useIntl} from "react-intl";
import _ from "loadsh";
import {useSafeOpenDetail} from "../../../Utils/Data/hooks/masterDetail";
import MasterDetailContextMenu from "../../../Components/MasterDetail/MasterDetailContextMenu";
import ActionToolbar from "../../../Components/MasterDetail/ActionToolbar";
import {DetailContextMenu} from "../../../Components/MasterDetail";
import {openDialog} from "../../../Utils/Data/actions/gui";
import {useStore} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MasterDetailTable from "../../../Components/MasterDetail/MasterDetailTable";
import {faEye, faUsers} from "@fortawesome/pro-solid-svg-icons";
import ViewDetail from "./ViewDetail";
import {useGetInMemoryDataList} from "../../../Utils/Data/hooks/dataList";

export default function Views() {
    const intl = useIntl();
    const store = useStore();

    const columns = useMemo(() => [
        {
            field: 'name', sortable: true, width: 200, renderCell: (params) => {
                return <Button><FontAwesomeIcon icon={faUsers}/>&nbsp;{params.row.name}</Button>
            }
        },
        {field: 'is_active', width: 150, type: "boolean"},
        {
            field: "view-users",
            width: 120, renderCell: (params) => <Button
                style={{textAlign: "center"}}
                variant="outlined"
                color="primary"
                size="small"
                onClick={(e) => {
                    e.stopPropagation();
                    openDialog(store, {type: "viewEditUsers", entity: params.row});
                }}>
                {formatForId(intl, "pages.view.users")}
            </Button>
        },
        {
            field: "view-groups",
            width: 120, renderCell: (params) => <Button
                style={{textAlign: "center"}}
                variant="outlined"
                color="primary"
                size="small"
                onClick={(e) => {
                    e.stopPropagation();
                    openDialog(store, {type: "viewEditGroups", entity: params.row});
                }}>
                {formatForId(intl, "pages.view.groups")}
            </Button>
        },
        {
            field: "view-copy",
            width: 120, renderCell: (params) => <Button
                style={{textAlign: "center"}}
                variant="outlined"
                color="primary"
                size="small"
                onClick={(e) => {
                    e.stopPropagation();
                    openDialog(store, {type: "viewCopy", entity: params.row});
                }}>
                {formatForId(intl, "pages.view.copy")}
            </Button>
        }

    ], []);

    const safeOpenDetail = useSafeOpenDetail();

    const dataSource = useGetInMemoryDataList({
        url: "view/list/",
        defaultSort: {field: "name", isAscending: true},
    });

    return <MasterDetailView loading={dataSource.loading} loadingError={dataSource.error}
                             onRenderContextMenu={({isDetailOpened, viewMode}) =>
                                 <MasterDetailContextMenu isDetailOpened={isDetailOpened} viewMode={viewMode}
                                                          onRenderDetailMenu={() => <DetailContextMenu
                                                              onRenderBeforeLabel={() => <FontAwesomeIcon
                                                                  icon={faEye}/>}/>}
                                                          onRenderMasterMenu={() => <ActionToolbar
                                                              localizationContext={"pages.views"}
                                                              actions={[{
                                                                  id: "create",
                                                                  onClick() {
                                                                      safeOpenDetail("views", {
                                                                          isNew: true,
                                                                          is_active: true
                                                                      });
                                                                  }
                                                              }]} searchFieldName={"name"}/>}/>}
                             onRenderDetail={() => <ViewDetail/>}>

        <MasterDetailTable dataSource={dataSource} entityType={"view"} columns={columns}/>
    </MasterDetailView>

}