import React, { useMemo } from "react";
import { CustomForm } from "../../../Components/Forms/CustomForm";
import { useMasterDetailFormSave, useOpenedDetailEntity } from "../../../Utils/Data/hooks/masterDetail";
import ActionToolbar from "../../../Components/MasterDetail/ActionToolbar";
import { useStore } from "react-redux";
import { openDialog } from "../../../Utils/Data/actions/gui";
import { DEFAULT_APP_FIELDS_ORDER, DEFAULT_DATA_FIELDS_ORDER, SPECIAL_BUTTONS_APP_FIELD, SPECIAL_BUTTONS_LIST } from "../../../Utils/Data/ViewsDefinitions";
import _ from "loadsh";
import { Friction, NiraDataTypes, None } from "../../../Components/NiraApi";

const formConfig = [
    {
        id: "name",
        type: "TextField",
        props: {
            required: true,
        },
    },
    {
        id: "is_active",
        type: "CheckboxField",
        props: {},
    },
    {
        id: "initial_view",
        type: "DropdownField",
        props: {
            multiple: false,
            items: ["column", "table", "map", "camera"],
            localizationContext: "forms.view.initial_view.items",
        },
    },
    {
        id: "nira_field",
        type: "DropdownField",
        props: {
            multiple: false,
            items: NiraDataTypes,
            localizationContext: "pages.views.mapView.nira.dataType",
        },
    },
    {
        id: "visible_app_fields",
        type: "DropdownField",
        props: {
            multiple: true,
            allowChangeOrder: true,
            items: DEFAULT_APP_FIELDS_ORDER,
            localizationContext: "forms.view.app_fields.items",
            defaultValue: [],
        },
        onValueChanged(config, formik, value) {
            if (value) {
                const present = value.indexOf(SPECIAL_BUTTONS_APP_FIELD) !== -1;

                const newConfig = _.clone(config);
                const specialButtonsField = _.find(newConfig, (item) => item.id === "special_buttons");
                const index = _.indexOf(newConfig, specialButtonsField);
                const field = _.clone(specialButtonsField);
                field.props.immutable = !present;
                newConfig[index] = field;

                if (!present) {
                    formik.handleChange({
                        target: {
                            name: "special_buttons",
                            value: [],
                        },
                    });
                }
                return newConfig;
            }
        },
    },
    {
        id: "special_buttons",
        type: "DropdownField",
        hidden: true,
        props: {
            multiple: true,
            items: SPECIAL_BUTTONS_LIST,
            localizationContext: "forms.view.special_buttons.items",
            defaultValue: [],
        },
    },
    {
        id: "visible_data_fields",
        type: "DropdownField",
        props: {
            allowChangeOrder: true,

            multiple: true,
            items: DEFAULT_DATA_FIELDS_ORDER,
            localizationContext: "value.meteo_v1.name",
            defaultValue: [],
        },
    },
    {
        id: "data_query",
        type: "QueryField",
        props: {},
        defaultValue: {
            filter: [],
            order: null,
        },
        onValueChanged(config, formik, value) {
            const newConfig = _.clone(config).filter((item) => item.id !== "map_zoom" && item.id !== "map_lat" && item.id !== "map_lon");
            if (_.isEmpty(value?.filter)) {
                return newConfig.concat(mapCustomFieldsConfig);
            } else {
                return newConfig;
            }
        },
    },
];

const onMapFieldValueChanged = (config, formik, value) => {
    if (value) {
        const newConfig = _.clone(config);
        const initialViewField = _.find(newConfig, (item) => item.id === "initial_view");
        const index = _.indexOf(newConfig, initialViewField);
        const field = _.clone(initialViewField);
        field.props.immutable = true;
        newConfig[index] = field;

        formik.handleChange({
            target: {
                name: "initial_view",
                value: "map",
            },
        });

        formik.handleChange({
            target: {
                name: "nira_field",
                value: Friction,
            },
        });

        const niraTypeField = _.find(newConfig, (item) => item.id === "nira_field");
        const niraIndex = _.indexOf(newConfig, niraTypeField);
        const niraField = _.clone(niraTypeField);
        niraField.props.items = niraField.props.items.filter((item) => item !== None);
        newConfig[niraIndex] = niraField;

        return newConfig;
    }
};
const mapCustomFieldsConfig = [
    {
        id: "map_zoom",
        type: "NumberField",
        props: {
            minValue: 3,
            maxValue: 18,
        },
        onValueChanged: onMapFieldValueChanged,
    },
    {
        id: "map_lat",
        type: "NumberField",
        props: {
            minValue: -90,
            maxValue: 90,
        },
        onValueChanged: onMapFieldValueChanged,
    },
    {
        id: "map_lon",
        type: "NumberField",
        props: {
            minValue: -180,
            maxValue: 180,
        },
        onValueChanged: onMapFieldValueChanged,
    },
];
export default function ViewDetail({ reloadCallback }) {
    const { makeSubmitCallback } = useMasterDetailFormSave("view/");
    const { entity, timeOpened } = useOpenedDetailEntity();
    const store = useStore();

    const formConfigWithCustomFields = useMemo(() => {
        if (_.isEmpty(entity?.data_query?.filter)) {
            return formConfig.concat(mapCustomFieldsConfig);
        } else {
            return formConfig;
        }
    }, [entity]);

    const openedEntityWithCustomProps = useMemo(() => {
        const { map_zoom, map_lat, map_lon } = !_.isEmpty(entity.gui_custom_field)
            ? JSON.parse(entity.gui_custom_field)
            : {
                  map_zoom: undefined,
                  map_lat: undefined,
                  map_lon: undefined,
              };
        return { ...entity, map_zoom, map_lat, map_lon };
    }, [entity]);

    const submitCallback = makeSubmitCallback({
        onBeforeSubmit: (values) => {
            let { map_zoom, map_lat, map_lon, data_query } = values;

            if (!_.isEmpty(data_query?.filter)) {
                map_zoom = null;
                map_lat = null;
                map_lon = null;
            }

            values.gui_custom_field = JSON.stringify({
                map_zoom,
                map_lat,
                map_lon,
            });
        },
        onSuccess: reloadCallback,
    });
    const actions = [
        {
            id: "users",
            onClick() {
                openDialog(store, { type: "viewEditUsers", entity: entity });
            },
        },
        {
            id: "groups",
            onClick() {
                openDialog(store, { type: "viewEditGroups", entity: entity });
            },
        },
        {
            id: "copy",
            onClick() {
                openDialog(store, { type: "viewCopy", entity: entity });
            },
        },
    ];

    return (
        <>
            {!entity.isNew && <ActionToolbar localizationContext="pages.view" actions={actions} detailToolbar={true} buttonProps={{ variant: "outlined" }} />}
            <CustomForm key={entity.id} timeOpened={timeOpened} formConfig={formConfigWithCustomFields} formData={openedEntityWithCustomProps} onSubmit={submitCallback} formId={"view"} />
        </>
    );
}
