import { useMediaQuery } from "@material-ui/core";

export function useWideScreen(minWidth = 1000) {
    return useMediaQuery(`(min-width:${minWidth}px)`);
}

export default function DesktopOnlyView({ minWidth, ...props }) {
    const { children } = props;
    const wideScreen = useWideScreen();

    return wideScreen ? children : <> </>;
}
