import React from "react";
import {Users} from "./User/Users";
import {Domains} from "./Domains";
import {Roles} from "./Role/Roles";
import UserGroups from "./UserGroup/UserGroups";
import RequireAdminPermissions from "../../Utils/Permissions/RequireAdminPermission";
import {Redirect, Route, useRouteMatch, Switch} from "react-router-dom";
import {Typography} from "@material-ui/core";
import Views from "./Views/Views";
import DataExport from "./DataExport/DataExport";
import NiraConfig from "./NiraConfig/NiraConfig";


export function Settings() {
    let {path, url} = useRouteMatch();
    return <RequireAdminPermissions>
        <Switch>
            <Redirect exact={true} path={path} to={`${path}users/`}/>

            <Route path={`${path}users/`}>
                <Users/>
            </Route>
            <Route path={`${path}userGroups/`}>
                <UserGroups/>
            </Route>
            <Route path={`${path}domains/`}>
                <Domains/>
            </Route>
            <Route path={`${path}roles/`}>
                <Roles/>
            </Route>
            <Route path={`${path}views/`}>
                <Views/>
            </Route>
            <Route path={`${path}general/`}>
                <General/>
            </Route>
            <Route path={`${path}data-export/`}>
                <DataExport/>
            </Route>
            <Route path={`${path}nira-config/`}>
                <NiraConfig />
            </Route>
        </Switch>
    </RequireAdminPermissions>
}

function General() {
    return <Typography variant={"h5"}>{"General"}</Typography>
}