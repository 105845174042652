import React, { useEffect, useMemo, useState } from "react";
import { useActiveAlertCounter } from "../Utils/Data/hooks/server";
import { useAudioAlertEnabled } from "../Utils/Data/hooks/gui";
import { openDialog } from "../Utils/Data/actions/gui";
import { useStore } from "react-redux";
import { useIntervalWhen } from "rooks";

export default function useWarningSound() {
    const store = useStore();

    const { notConfirmedConfirmableAlerts, confirmableMaxLevel } = useActiveAlertCounter();
    const [prevCounter, setPrevCounter] = useState(notConfirmedConfirmableAlerts);
    const [level] = useAudioAlertEnabled();

    const audio = useMemo(() => new Audio("https://commondatastorage.googleapis.com/codeskulptor-assets/jump.ogg"), []);

    const playAudio = () => {
        const playPromise = audio.play();

        if (playPromise !== undefined) {
            playPromise
                .then((_) => {})
                .catch((error) => {
                    openDialog(store, { type: "errorAudio" });
                    console.log("playback prevented", error);
                });
        }
    };
    const shouldPlayAudio = () => {
        return level > 0 && confirmableMaxLevel >= level && notConfirmedConfirmableAlerts > 0;
    };

    useEffect(() => {
        if (shouldPlayAudio() && prevCounter < notConfirmedConfirmableAlerts) {
            playAudio();
        }
        setPrevCounter(notConfirmedConfirmableAlerts);
    }, [notConfirmedConfirmableAlerts]);

    useIntervalWhen(
        () => {
            playAudio();
        },
        1000 * 60,
        shouldPlayAudio(),
        false
    );
}
