import React, { useMemo } from "react";
import { CustomForm } from "../../../Components/Forms/CustomForm";
import { useMasterDetailFormSave, useOpenedDetailEntity } from "../../../Utils/Data/hooks/masterDetail";
import ActionToolbar from "../../../Components/MasterDetail/ActionToolbar";
import { useStore } from "react-redux";
import { openDialog } from "../../../Utils/Data/actions/gui";
import _ from "loadsh";

const formConfig = [
    {
        id: "name",
        type: "TextField",
        props: {
            required: true,
        },
    },
    {
        id: "note",
        type: "TextField",
        props: {},
    },
    {
        id: "is_active",
        type: "CheckboxField",
        props: {},
    },
    {
        id: "default_login_path",
        type: "TextField",
        props: {},
    },
    {
        id: "default_alert_filter",
        type: "AlertFilter",
    },
];
export default function UserGroupDetail({ reloadCallback }) {
    const { makeSubmitCallback } = useMasterDetailFormSave("group/");
    const { entity, timeOpened } = useOpenedDetailEntity();
    const store = useStore();

    const actions = [
        {
            id: "editRoles",
            onClick() {
                openDialog(store, { type: "userGroupEditRoles", entity: entity });
            },
        },
    ];

    const openedEntityWithCustomProps = useMemo(() => {
        const { default_alert_filter } = !_.isEmpty(entity.custom_props)
            ? JSON.parse(entity.custom_props)
            : {
                  default_alert_filter: {
                      filter: [],
                      levelFilter: [],
                  },
              };

        const result = {
            ...entity,
            default_alert_filter: default_alert_filter || { filter: [], levelFilter: [] },
        };

        return result;
    }, [entity]);

    const submitCallback = makeSubmitCallback({
        onBeforeSubmit: (values) => {
            const { default_alert_filter } = values;

            values.custom_props = JSON.stringify({
                default_alert_filter,
            });
        },
    });

    return (
        <>
            {!entity.isNew && <ActionToolbar localizationContext="pages.userGroup" actions={actions} detailToolbar={true} buttonProps={{ variant: "outlined" }} />}
            <CustomForm key={entity.id} timeOpened={timeOpened} formConfig={formConfig} formData={openedEntityWithCustomProps} onSubmit={submitCallback} formId={"userGroup"} />
        </>
    );
}
