import { useIntl } from "react-intl";
import { LayersControl, useMap, useMapEvents } from "react-leaflet";
import VectorTileLayer from "react-leaflet-vector-tile-layer";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { formatForId } from "../../Utils/Lang/IntlHelper";
import { useSelectedDataType } from "../../Utils/Data/hooks/map";
import { None } from "../NiraApi";
import { useStore } from "react-redux";
import { setSelectedMapType } from "../../Utils/Data/actions/map";
import { useDelayedEffect } from "../../Utils/Hooks/DelayedHooks";

const TILE_URL = "https://geo.mirsa.sk";

export const BrightMap = "bright";
export const DarkMap = "dark";

function MapOverlay({ mapKey }) {
    const niraOverlayRef = useRef();

    const overlayUrl = useMemo(() => {
        return TILE_URL + `/styles/${mapKey}_overlay/style.json`;
    }, [mapKey]);

    const map = useMap();
    useEffect(() => {
        if (niraOverlayRef.current) {
            niraOverlayRef.current._container.parentElement.style.pointerEvents = "none";
        }
    }, [mapKey, niraOverlayRef]);

    useEffect(() => {
        if (niraOverlayRef.current) {
            map.removeLayer(niraOverlayRef.current);
            niraOverlayRef.current.options.style = overlayUrl;
            map.addLayer(niraOverlayRef.current);
            const center = map.getCenter();
            map.setView({ lat: center.lat + 0.001, lng: center.lng + 0.001 });
            map.setView(center);
        }
    }, [overlayUrl]);

    if (mapKey) {
        return <VectorTileLayer styleUrl={overlayUrl} pane={"shadowPane"} ref={niraOverlayRef} />;
    } else return <></>;
}

export default function BaseTileLayer({}) {
    const intl = useIntl();
    const store = useStore();
    const niraDataType = useSelectedDataType();

    const isNiraEnabled = useMemo(() => niraDataType !== None, [niraDataType]);
    const [mapKey, setMapKey] = useState(null);
    const ref = useRef();

    useMapEvents({
        baselayerchange(e) {
            if (e.layer.options.style.includes("nira")) {
                setSelectedMapType(store, DarkMap);
            } else {
                setSelectedMapType(store, BrightMap);
            }

            const style = e.layer.options.style;
            if (style.includes("my-style_base")) {
                setMapKey("my-style");
            } else if (style.includes("bright_base")) {
                setMapKey("bright");
            } else if (style.includes("nira")) {
                setMapKey("nira");
            }
        },
    });

    useEffect(() => {
        if (ref.current && isNiraEnabled) {
            const niraLayer = ref.current._layers.find((layer) => layer.layer.options.style.includes("my-style"));
            if (niraLayer) {
                ref.current._layerControlInputs[2].click();
            }
        }
    }, [ref, isNiraEnabled]);

    return (
        <>
            <MapOverlay mapKey={mapKey} />
            <LayersControl position="bottomleft" ref={ref}>
                <LayersControl.BaseLayer checked={true} name={formatForId(intl, "pages.views.mapView.tileOverlay.theme.bright")} mapType={BrightMap}>
                    <VectorTileLayer styleUrl={TILE_URL + "/styles/bright_base/style.json"} />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name={formatForId(intl, "pages.views.mapView.tileOverlay.theme.positron")} mapType={BrightMap}>
                    <VectorTileLayer styleUrl={TILE_URL + "/styles/my-style_base/style.json"} />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name={formatForId(intl, "pages.views.mapView.tileOverlay.theme.dark")} mapType={DarkMap}>
                    <VectorTileLayer styleUrl={TILE_URL + "/styles/nira/style.json"} />
                </LayersControl.BaseLayer>
            </LayersControl>
        </>
    );
}
