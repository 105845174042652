import {Dialog, IconButton} from "@material-ui/core";
import React from "react";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/pro-solid-svg-icons";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    cancelButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
}));


export function CancelButton({onClose}) {
    const classes = useStyles();
    return <IconButton color="inherit" onClick={onClose} className={classes.cancelButton}>
        <FontAwesomeIcon icon={faTimes} size={"xs"}/>
    </IconButton>
}

export function CommonDialog({onClose, noButton, children, ...props}) {

    const events = noButton ? {} : {onEscapeKeyDown: onClose};
    return (
        <Dialog open={true} {...props} fullWidth={true} {...events} >
            {!noButton && <CancelButton onClose={onClose}/>}
            {children}
        </Dialog>
    )

}
