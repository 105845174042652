import React from "react"
import {Toolbar} from "@material-ui/core";
import {ViewMode} from "../../Utils/Data/reducers/gui.default";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme=>({
   masterDetailContextMenu: {
      borderBottom: "1px solid #343B46", paddingRight: 0, paddingLeft: theme.spacing(1)
   }
}))

export default function MasterDetailContextMenu({isDetailOpened, viewMode, onRenderMasterMenu, onRenderDetailMenu}) {
   const classes = useStyles();


   return <Toolbar className={classes.masterDetailContextMenu}>
      <div style={{flexGrow: 1, maxWidth: isDetailOpened && viewMode === ViewMode.MasterDetail? "50%": "100%"}}>{onRenderMasterMenu && onRenderMasterMenu({isDetailOpened, viewMode})}</div>
      {isDetailOpened && viewMode === ViewMode.MasterDetail &&
      <div style={{flexGrow: 1, maxWidth: "50%"}}>{onRenderDetailMenu && onRenderDetailMenu({isDetailOpened, viewMode})}</div>
      }
   </Toolbar>
}