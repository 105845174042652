import {useIntl} from "react-intl";
import {connect, useStore} from "react-redux";
import {
   useOpenedDetailEntity,
   useUserChanged,
   useUserDataSafeMasterDetailChange
} from "../../Utils/Data/hooks/masterDetail";
import {FormControlLabel, IconButton, Switch, Toolbar, Typography, useTheme} from "@material-ui/core";
import {closeDetail, setEditDetail} from "../../Utils/Data/actions/masterDetail";
import {formatForId} from "../../Utils/Lang/IntlHelper";
import Button from "@material-ui/core/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faWindowClose} from "@fortawesome/pro-solid-svg-icons";
import React from "react";
import {makeStyles} from "@material-ui/styles";
import {ReduxEvent, dispatchEvent} from "react-redux-events";
import {FormSubmitEvent} from "../../Utils/Data/Events";

const useStyles = makeStyles((theme)=>({
   barItem: {
      marginRight: theme.spacing(1)
   },
   customTools: {
      marginRight: theme.spacing(1),
      marginLeft:  theme.spacing(1)
   }
}))

const mapProps =  state => ({});
const mapDispatch = dispatch => ({
   dispatchEvent: (event) => dispatch(dispatchEvent(event))
});

export default connect(mapProps, mapDispatch)(function DetailContextMenu({onRenderBeforeLabel, dispatchEvent, readOnly}) {
   const intl = useIntl();
   const store = useStore();
   const theme = useTheme();
   const classes = useStyles(theme);

   const{entity, detailEditable, entityType} = useOpenedDetailEntity();
   const {userChanged, confirmWhenNeeded}  = useUserDataSafeMasterDetailChange();

   const name = entity.isNew ? formatForId(intl, `forms.${entityType}.create`): entity.name;

   return <Toolbar style={{paddingRight:0}}>

      {
         onRenderBeforeLabel && <div className={classes.barItem}> {onRenderBeforeLabel({entity})} </div>
      }

      {
         <Typography variant={"h6"}>
            {userChanged && <i title={formatForId(intl, "form.userChanged")}>{name} *</i> }
            {!userChanged && name }
         </Typography>
      }

      <div style={{flexGrow:1}}></div>
      {
         !readOnly && !entity.isNew && (
            <FormControlLabel
               control={
                  <Switch
                     checked={detailEditable}
                     onChange={(evt) => {
                        confirmWhenNeeded(()=>setEditDetail(store, !detailEditable))
                     }}
                     color={"primary"}
                  />
               }
               label={formatForId(intl, "forms.editable")}
            />)
      }
      {!readOnly &&
      <Button variant="contained" color="secondary" disabled={!detailEditable} onClick={()=>dispatchEvent(new ReduxEvent(FormSubmitEvent,{ userChanged, action: "save"}))}>
         <FontAwesomeIcon icon={faSave} className={classes.barItem} />
         {formatForId(intl, "form.submit")}
      </Button>}

      <IconButton  color="default" onClick={()=>confirmWhenNeeded(()=>closeDetail(store))}>
         <FontAwesomeIcon icon={faWindowClose}   />

      </IconButton>

   </Toolbar>
});
