export default {
    devData: {},
    liveData: {},
    connected: false,
    historyDate: null,
    historyState: {},
    globalState: {not_confirmed_alerts: 0, not_confirmed_alerts_max_level: 0},
    appAccess: {...window.AppAccess},
    camImages: {},
    buildInfo: window.BuildInfo,
    uiRefresh: {devGroups: {}, devices: {}, domains: {}, global: 0, views: {}, viewChangeCounter: 0, radarImageChangeCounter: 0}
}