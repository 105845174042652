import React from 'react';
import TextField from '@material-ui/core/TextField';
import {FormControl, FormLabel, TextareaAutosize, useTheme} from "@material-ui/core";
import {formatForId} from "../../Utils/Lang/IntlHelper";


export function CustomTextField({inputRef,fieldDef, required, fieldId, label, value, changeHandle, touched, errors, type, disabled, autoComplete, formatter}) {


    return (
        <FormControl fullWidth required={!!required} {...fieldDef.props?.formControl}>
            <FormLabel component="legend" {...fieldDef.props?.formLabel}>{label}</FormLabel>
            <TextField
                autoComplete={autoComplete || "off"}
                inputRef={inputRef}
                disabled={disabled}
                type={type}
                variant={"outlined"}
                name={fieldId}
                value={formatter?formatter(value):value}
                onChange={changeHandle}
                error={touched && Boolean(errors)}
                helperText={touched && errors}

                {...fieldDef.props?.inputField}
            />

        </FormControl>
    );
}




export function CustomTextAreaField({inputRef,fieldDef, required, fieldId, label, value, changeHandle, touched, errors, type, disabled, autoComplete, formatter}) {

    const theme = useTheme();
    return (
        <FormControl fullWidth required={!!required} {...fieldDef.props?.formControl}>
            <FormLabel component="legend" {...fieldDef.props?.formLabel}>{label}</FormLabel>
            <TextareaAutosize
                style={{backgroundColor: "transparent", color: "white", borderRadius: 5, border: "1px solid gray"}}
                inputRef={inputRef}
                disabled={disabled}
                variant={"outlined"}
                minRows={20}
                name={fieldId}
                value={formatter?formatter(value):value}
                onChange={changeHandle}
                error={touched && Boolean(errors)}
                helperText={touched && errors}

                {...fieldDef.props?.inputField}
            />

        </FormControl>
    );
}
