import DialogForm from "./DialogForm";
import {FieldArray} from "formik";
import {IconButton, Input} from "@material-ui/core";
import _ from "loadsh";
import {buildFormEntityValues, buildFormValidation, RenderField} from "./FormCommon";
import {markUserChanged} from "../../Utils/Data/actions/masterDetail";
import {useIntl} from "react-intl";
import {faPlus, faTrashAlt} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {makeStyles} from "@material-ui/styles";
import {formatForId} from "../../Utils/Lang/IntlHelper";
import {useMemo} from "react";

const useStyles = makeStyles(theme => ({
   button: {
      borderRadius: theme.spacing(1)
   },
   addIcon:
      {
         marginRight: theme.spacing(2)
      }
}))

export function getErrors(errors, index, field)
{

   if (errors)
   {

      const errorOnIndex = errors[index];
      if (errorOnIndex)
      {
         const error = errorOnIndex[field];
         if (error) {
            return error;
         }
      }
   }
   return null;
}

export default function FormList({formik, fieldId, touched, errors, fieldDef, disabled, changeHandle, setValue, value = []}) {
   const classes = useStyles();

   const intl = useIntl();

   //const validationSchema = useMemo(() => buildFormValidation(intl, fieldDef.props.formConfig), [fieldDef.props.formConfig]);

   const changeValue = (value) => {
      changeHandle({target: {name: fieldId, value}});
   }

   const removeIndex = (removedIndex) => {
      changeValue(_.filter(value, (val, idx) => idx !== removedIndex));
   }
   const add = async () => {

      if (value) {
         //console.log(formik, formik.validate);
         await formik.validateForm();
      }

      const newValue = _.clone(value)
      newValue.push(buildFormEntityValues(fieldDef.props.formConfig, {}));

      changeValue(newValue);
   }




   return (
      <div style={{display: "flex", flexDirection: "column"}}>
         {value && value.length > 0 &&
         value.map((element, index) => {
         return (<div key={index} style={{display: "flex", flexDirection: "row"}}>
                  {
                     fieldDef.props.formConfig.map((field) => RenderField({
                        intl, changeHandle: (evt) => {

                           const newValue = _.clone(value);
                           newValue[index][field.id] = evt.target.value;

                           changeValue(value);

                        }, value: value[index][field.id], item: field, formik, fieldId: `${fieldId}[${index}].${field.id}`,
                        formId: fieldDef.props.formId, editable: !disabled, orderIndex: index + 1,
                        errors: getErrors(errors, index,field.id),
                        touched: !_.isEmpty(getErrors(errors, index,field.id))
                  }))
                  }
                  {!disabled &&

                  <IconButton onClick={() => removeIndex(index)} className={classes.button}>
                     <FontAwesomeIcon icon={faTrashAlt}/>
                  </IconButton>
                  }

               </div>
            );
         })}
         {!disabled &&
         <IconButton onClick={() => add()} className={classes.button}>
            <FontAwesomeIcon icon={faPlus} className={classes.addIcon}/>
            {formatForId(intl, `forms.${fieldId}.addNew`)}
         </IconButton>}
      </div>);

}