export default {
    selectedItem: {},
    currentOverlay: [],
    imageOverlayEnabled: false,
    overlayIndex: 0,
    imageOverlayPaused: false,
    selectedDataType: "none",
    niraDataPanelEnabled: false,
    selectedMapType: "",
    selectedRoughnessAlertTime: 5,
    niraPlayerFrame: null,
    selectedPlayerTime: 0,
    devicesHidden: false,
    insufficientNiraZoom: false,
};
