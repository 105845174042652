import { ALERT_DEVICE_OFFLINE } from "../../../../../Utils/Data/AlertFormatter";
import moment from "moment";
import _ from "loadsh";
import { getDeviceChartData } from "./DeviceStateChart";
import { formatDayTimeShort, formatTimeOnly } from "../../../../../Utils/Data/Time";
import { isValidValue } from "../../../../../Utils/Data/ValueMapper";
import { precipTooltipFormatter, waterThickTooltipFormatter } from "./TooltipFormatters";

const MIN_MAX_MULTIPLIER = 0.075;

function findOfflineStartPoints(alertData) {
    const getOfflineItems = (items) => items.filter((item) => item.level === ALERT_DEVICE_OFFLINE);

    let ret = [];

    getOfflineItems(alertData.active).forEach((item) => ret.push(item.begin_time));
    getOfflineItems(alertData.notActive).forEach((item) => ret.push(item.begin_time));

    return ret;
}

const labelFormatter = () => {
    let lastValue = null;

    return ({ value }) => {
        if (lastValue !== null && moment(lastValue).isSame(moment(value), "days")) {
            return formatTimeOnly(moment(value), false);
        } else {
            lastValue = value;
            return formatDayTimeShort(value);
        }
    };
};

function getChartOptions({
    chartData,
    theme,
    chartStartPoint,
    chartEndPoint,

    reversed,
    hideX,
    yLabelFormatter = (e) => e.value.toFixed(2).replace(".", ","),
    axisY2 = [],
    stripLineY = {},
    stripLineX = {},
    axisYMaximum,
    axisYMinimum,
    tooltipContentFormatter = null,
    onViewportChanged,
}) {
    const options = {
        animationEnabled: false,
        zoomEnabled: true,

        toolTip: {
            enabled: tooltipContentFormatter !== null,
            contentFormatter: tooltipContentFormatter,
            cornerRadius: 4,
            borderColor: "rgba(255, 255, 255, 0.23)",
            backgroundColor: "rgba(52, 59, 70, 0.8)",
        },
        axisY: {
            ...defaultAxisYOptions,
            labelFormatter: yLabelFormatter,
            reversed: reversed,
            gridThickness: 0.4,
            lineThickness: 0.4,
            tickThickness: 0.4,
            stripLines: [stripLineY],
        },
        axisY2: axisY2,

        axisX: {
            minimum: chartStartPoint.x,
            maximum: chartEndPoint.x,
            labelFontSize: 14,
            gridThickness: 0.4,
            lineThickness: 0.4,
            tickThickness: 0.4,
            labelFormatter: labelFormatter(),
            // valueFormatString: "DD. MM. HH:mm",

            stripLines: [stripLineX],
        },
        data: chartData,
        dataPointMinWidth: 2,
        legend: {
            fontSize: 14,
            verticalAlign: "top",
            fontWeight: "lighter",
        },
        rangeChanging: onViewportChanged,
    };

    if (hideX) {
        // options.axisX.lineThickness = 0;
        options.axisX.tickLength = 0;
        options.axisX.labelFormatter = () => "";
    }

    if (axisYMaximum) {
        options.axisY.maximum = axisYMaximum;
    }

    if (axisYMinimum !== undefined) {
        options.axisY.minimum = axisYMinimum;
    }

    return options;
}

function getChartBoundaries(history, beginTime) {
    const calcEndTimeForForecast = () => {
        let result = Date.now();
        const length = result - history.value.begin;
        result += (1 / 8) * length;
        return result;
    };

    const endTime = history.value.end_time ? history.value.end_time - 1000 : calcEndTimeForForecast();

    const chartStartPoint = {
        time: beginTime,
        x: new Date(beginTime),
        y: null,
    };
    const chartEndPoint = {
        time: endTime,
        x: new Date(endTime),
        y: null,
    };

    const viewportMinimum = history.value.begin_time;
    const viewportMaximum = chartEndPoint.time;

    return [chartStartPoint, chartEndPoint, viewportMinimum, viewportMaximum];
}

const defaultAxisYOptions = {
    labelFontSize: 14,
};

const precipTypeDependency = {
    dependentType: "precip_type",
    getColor: (x) => {
        if (x === 0) {
            return "#00c030";
        } else if ((x >= 30 && x <= 35) || (x >= 4 && x <= 5) || (x >= 10 && x <= 11)) {
            return "#c09000";
        } else if ((x >= 40 && x <= 44) || (x >= 50 && x <= 53) || (x >= 57 && x <= 63) || (x >= 81 && x <= 84)) {
            return "#1E90FF";
        } else if ((x >= 70 && x <= 78) || (x >= 85 && x <= 89) || (x >= 27 && x <= 29) || (x >= 45 && x <= 46)) {
            return "#949494";
        } else if (x >= 67 && x <= 68) {
            return "#c00030";
        } else if ((x >= 47 && x <= 48) || (x >= 54 && x <= 56) || (x >= 64 && x <= 66)) {
            return "#D42C88";
        }
        return "#ABC321";
    },
};

const coloringOptions = {
    precip: precipTypeDependency,
    precip_intens: precipTypeDependency,
    water_thick: {
        dependentType: "road_status",
        getColor: (x) => {
            switch (x) {
                case 0:
                    return "#00c030";
                case 1:
                case 32:
                    return "#1E90FF";
                case 64:
                    return "#c09000";
                case 65:
                    return "#949494";
                case 66:
                    return "#D42C88";
                case 67:
                    return "#c00030";
                default:
                    return "#ABC321";
            }
        },
    },
};

/**
 * This function offers faster data transformation by not wasting memory bandwidth and removing branch misses
 */
function transformToDataPoints(data, columns, chartStartPoint, chartEndPoint, lineDashType, insertSentinel = true, sort = false) {
    const columnDataPoints = columns.map((column) => []);
    const columnHasData = columns.map((col) => false);

    const columnMinimum = columns.map((col) => undefined);
    const columnMaximum = columns.map((col) => undefined);

    const len = data?.length ? data.length : 0;

    // insert data start sentinel
    if (insertSentinel) {
        for (let j = 0, jlen = columns.length; j !== jlen; ++j) {
            columnDataPoints[j].push({
                time: chartStartPoint.time,
                x: new Date(chartStartPoint.time),
                y: null,
                lineDashType: lineDashType,
            });
        }
    }

    for (let i = 0; i !== len; ++i) {
        const row = data[i];

        if (row.time < chartStartPoint.time) continue;

        sort |= row.time > chartEndPoint.time;

        const x = new Date(row.time);

        for (let j = 0, jlen = columns.length; j !== jlen; ++j) {
            const column = columns[j];
            let columnValue = row[column];
            if (!isValidValue(columnValue)) {
                columnValue = null;
            }

            columnHasData[j] |= !!columnValue;

            if (columnMinimum[j] === undefined || columnValue < columnMinimum[j]) {
                columnMinimum[j] = columnValue;
            }

            if (columnMaximum[j] === undefined || columnValue > columnMaximum[j]) {
                columnMaximum[j] = columnValue;
            }

            let color = "";
            if (coloringOptions.hasOwnProperty(column)) {
                const dependentVal = row[coloringOptions[column].dependentType];
                if (dependentVal !== undefined) {
                    color = coloringOptions[column].getColor(dependentVal);
                }
            }

            // turned branching into calculation if isInInterval then row.time is used otherwise  chartStartPoint.time
            columnDataPoints[j].push({
                time: row.time,
                x: x,
                y: columnValue,
                lineDashType: lineDashType,
                color: color,
                lineColor: color,
                valueKey: column,
            });
        }
    }

    // insert data end sentinel
    if (insertSentinel) {
        for (let j = 0, jlen = columns.length; j !== jlen; ++j) {
            columnDataPoints[j].push({
                time: chartEndPoint.time,
                x: new Date(chartEndPoint.time),
                y: null,
                lineDashType: lineDashType,
            });
        }
    }

    if (sort) {
        for (let j = 0, jlen = columns.length; j !== jlen; ++j) {
            const dataPoints = columnDataPoints[j];
            dataPoints.sort((a, b) => a.time - b.time);
        }
    }

    const result = [columnDataPoints, columnHasData, null, null, columnMinimum, columnMaximum];
    const firstColumnDataPoints = columnDataPoints[0];
    if (firstColumnDataPoints?.length) {
        const minimumTime = firstColumnDataPoints[0].time;
        const maximumTime = firstColumnDataPoints[firstColumnDataPoints.length - 1].time;

        result[2] = minimumTime;
        result[3] = maximumTime;
    }

    return result;
}

function mergeOfflineStartPoints(alertData, columnDataPoints, history, columns, columnHasData, columnForecastHasData, beginTime) {
    const offlineStartPoints = findOfflineStartPoints(alertData);
    let now = Date.now();

    const columnDataPointsOriginalLengths = columnDataPoints.map((dataPoints) => dataPoints.length);
    for (let o = 0, olen = offlineStartPoints.length; o !== olen; ++o) {
        const startPoint = offlineStartPoints[o];

        if (startPoint > beginTime && startPoint < (history.value.end_time ? history.value.end_time : now)) {
            const point = {
                time: startPoint,
                x: new Date(startPoint),
                y: null,
            };

            for (let j = 0, jlen = columns.length; j !== jlen; ++j) {
                if (!columnHasData[j] && !columnForecastHasData[j]) {
                    continue;
                }

                columnDataPoints[j].push(point);
            }
        }
    }

    // resort those columns that has changed
    for (let j = 0, jlen = columns.length; j !== jlen; ++j) {
        const dataPoints = columnDataPoints[j];
        if (dataPoints.length !== columnDataPointsOriginalLengths[j]) {
            dataPoints.sort((a, b) => a.time - b.time);
        }
    }
}

export function createChartOptions({ history, findValueInfo, data, forecastData, forecastActive, intl, theme, alertData, columns, alertsViewSettings, beginTime, hiddenColumns, onViewportChanged }) {
    const [chartStartPoint, chartEndPoint, viewportMinimum, viewportMaximum] = getChartBoundaries(history, beginTime);
    const [columnDataPoints, columnHasData, min, max, columnMinimum, columnMaximum] = transformToDataPoints(data, columns, chartStartPoint, chartEndPoint, "solid");
    const [columnForecastDataPoints, columnForecastHasData, minimum, maximum, forecastColumnMinimum, forecastColumnMaximum] = transformToDataPoints(
        forecastData,
        columns,
        chartStartPoint,
        chartEndPoint,
        "dot",
        false,
        true
    );
    mergeOfflineStartPoints(alertData, columnDataPoints, history, columns, columnHasData, columnForecastHasData, minimum, beginTime);

    let mainChartData = [];
    let steplineChartData = [];
    let column1ChartData = { data: [] };
    let column2ChartData = { data: [] };
    let axis2YOptions = [];
    let minMaxForUnit = {};

    for (let i = 0, len = columns.length; i !== len; ++i) {
        const column = columns[i];
        const valueInfo = findValueInfo(column);

        const dataPoints = columnDataPoints[i];
        const forecastDataPoints = columnForecastDataPoints[i];
        const isHidden = hiddenColumns && hiddenColumns.has(column);

        const handleMaxMin = (unit) => {
            const valueMax = forecastActive && forecastColumnMaximum[i] !== undefined ? Math.max(forecastColumnMaximum[i], columnMaximum[i]) : columnMaximum[i];
            const valueMin = forecastActive && forecastColumnMinimum[i] !== undefined ? Math.min(forecastColumnMinimum[i], columnMinimum[i]) : columnMinimum[i];

            if (_.isEmpty(minMaxForUnit[unit])) {
                minMaxForUnit[unit] = { min: valueMin, max: valueMax };
            } else {
                if (valueMax !== null && (minMaxForUnit[unit].max == null || minMaxForUnit[unit].max < valueMax)) {
                    minMaxForUnit[unit].max = valueMax;
                }
                if (valueMin !== null && (minMaxForUnit[unit].min == null || minMaxForUnit[unit].min > valueMin)) {
                    minMaxForUnit[unit].min = valueMin;
                }
            }
        };

        const options = {
            name: valueInfo?.nameFormatted,
            type: valueInfo && valueInfo.hasOwnProperty("graphType") ? valueInfo.graphType : "line",
            connectNullData: false,
            color: valueInfo?.color,
            markerType: "none",
            legendMarkerType: "none",
            showInLegend: false,
        };

        const handleForecast = (options, container) => {
            let forecastOptions = _.cloneDeep(options);

            forecastOptions.name = "";
            if (forecastActive) {
                forecastOptions.dataPoints = forecastDataPoints;
                container.push(forecastOptions);
            }
        };
        switch (options.type) {
            case "stepArea":
                steplineChartData.push(options);
                options.dataPoints = dataPoints;
                handleForecast(options, steplineChartData);
                break;
            case "column1":
                if (!isHidden) {
                    column1ChartData.data.push(options);
                    column1ChartData.formatter = (e) => valueInfo.graphFormatter(e.value);
                    options.dataPoints = dataPoints;
                    options.type = "stepArea";
                    options.showInLegend = true;

                    handleForecast(options, column1ChartData.data);
                }
                break;
            case "column2":
                if (!isHidden) {
                    column2ChartData.data.push(options);
                    column2ChartData.formatter = (e) => valueInfo.graphFormatter(e.value);
                    options.dataPoints = dataPoints;
                    options.type = "stepArea";
                    options.showInLegend = true;

                    handleForecast(options, column2ChartData.data);
                }
                break;
            case "scatter":
                options.markerType = "circle";
                options.markerSize = 5;
            case "area": //visibility only
                options.fillOpacity = 0.5;
                options.inverted = true;
            //fallthrough
            default:
                if (valueInfo.unit !== "celsius") {
                    options.axisYType = "secondary";
                    const idx = axis2YOptions.findIndex((item) => item.unit === valueInfo.unit);
                    if (idx === -1) {
                        const axis2YOption = {
                            ...defaultAxisYOptions,
                            unit: valueInfo.unit,
                            labelFormatter: (e) => valueInfo.graphFormatter(e.value, true),
                        };
                        if (valueInfo.unit === "percent") {
                            axis2YOption.minimum = 0;
                            axis2YOption.maximum = 100;
                        } else if (column === "visibility") {
                            axis2YOption.minimum = 0;
                            axis2YOption.maximum = 2000;
                        } else {
                            handleMaxMin(valueInfo.unit);
                        }

                        axis2YOptions.push(axis2YOption);
                        options.axisYIndex = axis2YOptions.length - 1;
                    } else {
                        options.axisYIndex = idx;
                    }
                } else {
                    handleMaxMin("celsius");
                }

                handleForecast(options, mainChartData);

                options.dataPoints = dataPoints;
                mainChartData.push(options);
        }
    }

    let charts = [];

    const pushSpecialChart = (chartData, reversed, axisYMaximum, axisYMinimum, yLabelFormatter, tooltipContentFormatter) => {
        if (chartData.length > 0) {
            charts.push(
                getChartOptions({
                    chartData: chartData,
                    theme: theme,
                    chartStartPoint,
                    chartEndPoint,
                    reversed: reversed,
                    hideX: true,
                    yLabelFormatter: yLabelFormatter ? yLabelFormatter : (e) => e.value,
                    axisYMaximum: axisYMaximum,
                    axisYMinimum: axisYMinimum,
                    tooltipContentFormatter,
                    onViewportChanged,
                })
            );
        }
    };

    if (alertsViewSettings.hasPermAlertView || alertsViewSettings.hasPermStates || alertsViewSettings.hasPermErrors) {
        charts.push(getDeviceChartData(alertData, history, theme, intl, chartStartPoint, chartEndPoint, alertsViewSettings, beginTime));
    }
    pushSpecialChart(steplineChartData, false, undefined, undefined, undefined, (e) => precipTooltipFormatter(e, intl));
    pushSpecialChart(column1ChartData.data, false, undefined, undefined, column1ChartData.formatter, (e) => precipTooltipFormatter(e, intl));
    pushSpecialChart(column2ChartData.data, false, undefined, undefined, column2ChartData.formatter, (e) => waterThickTooltipFormatter(e, intl));

    const getMinMaxForUnit = (unit) => {
        const minMax = minMaxForUnit[unit];
        if (!_.isEmpty(minMax)) {
            const diff = Math.abs(minMax.min) + Math.abs(minMax.max);
            return { min: minMax.min - diff * MIN_MAX_MULTIPLIER, max: minMax.max + diff * MIN_MAX_MULTIPLIER };
        } else {
            return undefined;
        }
    };
    axis2YOptions.forEach((axis2YOption) => {
        const minMax = getMinMaxForUnit(axis2YOption.unit);
        if (minMax) {
            axis2YOption.minimum = minMax.min;
            axis2YOption.maximum = minMax.max;
        }
    });

    const tempValueInfo = findValueInfo("temp");
    charts.push(
        getChartOptions({
            chartData: mainChartData,
            theme,
            chartStartPoint,
            chartEndPoint,

            reversed: false,
            yLabelFormatter: (e) => tempValueInfo.graphFormatter(e.value),
            axisY2: axis2YOptions,
            stripLineY: {
                label: tempValueInfo.graphFormatter(0),
                value: 0,
                thickness: 2.5,
            },
            stripLineX: {},
            axisYMaximum: getMinMaxForUnit(tempValueInfo.unit)?.max,
            axisYMinimum: getMinMaxForUnit(tempValueInfo.unit)?.min,
            onViewportChanged,
        })
    );

    for (const chart of charts) {
        const { axisX } = chart;
        // console.log(axisX)
        axisX.minimum = chartStartPoint.time;
        axisX.maximum = Math.max(maximum, chartEndPoint.time);

        axisX.viewportMinimum = viewportMinimum;
        axisX.viewportMaximum = viewportMaximum;
    }

    return {
        animationEnabled: true,
        exportEnabled: false,
        theme: "dark1", // "light1", "dark1", "dark2"
        zoomEnabled: true,
        rangeSelector: {
            enabled: false,
        },
        navigator: {
            enabled: false,
        },
        backgroundColor: "",
        charts: charts,
    };
}
